import React, { FC } from 'react';
import { Svg } from 'shared/ui/atoms/Svg';
import { ITabProps } from './types';
import { Text } from 'shared/ui/atoms/Text';
import { useTranslation } from 'react-i18next';
import { tabCss } from './style';
import { NavLink } from 'react-router-dom';

export const Tab: FC<ITabProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <NavLink
      to={data.endPoint}
      className={({ isActive }) =>
        `${tabCss.button} ${isActive && tabCss.buttonActive}`
      }>
      <Svg idIcon={data.idIcon} width={24} height={24} />
      <Text size="span" color="secondary">
        {t(data.title)}
      </Text>
    </NavLink>
  );
};
