import { styled } from '@linaria/react';
import { theming } from 'shared/theme';

export const WrapperSvg = styled.svg`
  display: flex;
  transition: color 0.2s ease-in-out;

  &[data-color='default'] {
    ${theming((theme) => ({ color: theme.svg.default }))}
  }

  &[data-color='secondary'] {
    ${theming((theme) => ({ color: theme.svg.secondary }))}
  }

  &[data-color='disabled'] {
    ${theming((theme) => ({ color: theme.svg.disabled }))}
  }
`;
