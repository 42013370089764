import { useUnit } from 'effector-react';
import { authModel } from 'entities/Auth/model';
import { ordersModel } from 'entities/Orders/model';
import { productsModel } from 'entities/Products/model';
import { servicesModel } from 'entities/Services/model';
import { statisticsModel } from 'entities/Statistics/model';
import { useEffect } from 'react';

export const useInitAuthorizedRequests = () => {
  const [merchantInfo, selectedService] = useUnit([
    authModel.creds.stores.$merchantInfo,
    servicesModel.selectedService.stores.$service,
  ]);

  useEffect(() => {
    if (merchantInfo) {
      servicesModel.services.events.getBusinessServices();
      servicesModel.services.events.getServices();

      if (selectedService) {
        productsModel.categories.events.getCategories();
        productsModel.products.events.getProducts();
        statisticsModel.stats.events.getStats();
        ordersModel.list.events.getOrders();
      }
    }
  }, [merchantInfo, selectedService]);
};
