import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import { IProductListByCategory } from '../types';
import { products } from './products';
import { getProductListByCategory } from '../lib/getProductListByCategory';
import { categories } from './categories';

const resetAll = createEvent();

const $data = createStore<IProductListByCategory[]>([]).reset(resetAll);

const getProductsListByCategoryFx = createEffect(getProductListByCategory);

const $isPending = getProductsListByCategoryFx.pending;

sample({
  clock: combine([products.stores.$list, categories.stores.$categories]),
  fn: ([products, categories]) => ({ products, categories }),
  target: getProductsListByCategoryFx,
});

sample({
  clock: getProductsListByCategoryFx.doneData,
  target: $data,
});

export const displayProducts = {
  stores: {
    $data,
  },
  events: {
    resetAll,
  },
  pendings: {
    $isPending,
  },
};
