import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { categoryControllerCss } from './style';
import { sharedCss } from 'shared/css';
import { Text } from 'shared/ui/atoms/Text';
import { ICategoryControllerProps } from './types';
import { Svg } from 'shared/ui/atoms/Svg';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { TOnChange, TOnClick } from 'shared/types/eventHandlers';
import { productsModel } from 'entities/Products/model';
import { useStoreMap } from 'effector-react';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { ContextMenu } from 'shared/ui/molecules/ContextMenu';

export const CategoryController: FC<ICategoryControllerProps> = ({
  data = null,
  isExpanded = false,
  isDisabled = false,
}) => {
  const contentMenuId = `category_${data.category_id}`;
  const inputRef = useRef<HTMLInputElement>(null);
  const { show } = useContextMenu({
    id: contentMenuId,
  });
  const isDeletePending = useStoreMap({
    keys: [data.category_id],
    store: productsModel.categories.pendings.$deletePendings,
    fn: (pendings, [categoryId]) =>
      pendings.some((pendingCategoryId) => pendingCategoryId === categoryId),
  });
  const isUpdatePending = useStoreMap({
    keys: [data.category_id],
    store: productsModel.categories.pendings.$updatePendings,
    fn: (pendings, [categoryId]) =>
      pendings.some((pendingCategoryId) => pendingCategoryId === categoryId),
  });
  const isCreatePending = useStoreMap({
    keys: [data.category_id],
    store: productsModel.categories.pendings.$createPendings,
    fn: (pendings, [categoryId]) =>
      pendings.some((pendingCategoryId) => pendingCategoryId === categoryId),
  });
  const isPending = isDeletePending || isUpdatePending || isCreatePending;
  const [isEdit, setIsEdit] = useState<boolean>(Boolean(data?.isNew));
  const [name, setName] = useState<string>(data.category_name);
  const stoped =
    data?.products?.filter((product) => !product?.is_available)?.length || 0;
  const productsLength = data?.products?.length || 0;
  const isReadOnly = !isEdit && Boolean(name.trim().length > 2);

  useEffect(() => {
    if (isEdit) inputRef.current?.focus();
  }, [isEdit]);

  const onClickOptions: TOnClick<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    show({ event });
  };

  const onEdit = () => {
    setIsEdit(true);
  };

  const onFocus = () => {
    !isReadOnly && setIsEdit(true);
  };

  const onBlur = () => {
    setIsEdit(false);

    if (data?.isNew && name.trim().length > 2) {
      productsModel.categories.events.createCategory({
        id: data.category_id,
        name,
      });
    }

    if (!data?.isNew && name.trim().length > 2 && name !== data.category_name) {
      productsModel.categories.events.updateCategory({
        id: data.category_id,
        name,
      });
    }
  };

  const onChange: TOnChange = (e) => {
    setName(e.target.value);
  };

  const onDelete = () => {
    confirmModalModel.modal.setVisible({
      isOpen: true,
      params: {
        title: 'Удалить категорию?',
        onConfirm: () => {
          data?.isNew
            ? productsModel.categories.events.deleteNewCategory(
                data.category_id,
              )
            : productsModel.categories.events.deleteCategory(data.category_id);
          confirmModalModel.modal.resetModal();
        },
      },
    });
  };

  return (
    <Fragment>
      <div className={categoryControllerCss.container}>
        <div className={sharedCss.rowBetween}>
          <input
            id={`category_field_${data.category_id}`}
            ref={inputRef}
            className={categoryControllerCss.input}
            value={name}
            placeholder="Введите название категории"
            readOnly={isReadOnly}
            onChange={onChange}
            onClick={(e) => !isReadOnly && e.stopPropagation()}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          {isPending && (
            <SpinnerBlock size={20} className={categoryControllerCss.spinner} />
          )}
          <div className={sharedCss.rowVerticalCenter}>
            {!isDisabled && (
              <button
                className={categoryControllerCss.button}
                data-is-expanded={isExpanded}>
                <Svg
                  idIcon="icLeftChevron"
                  width={16}
                  height={16}
                  className={categoryControllerCss.chevronIcon}
                />
              </button>
            )}
            <button
              className={categoryControllerCss.button}
              onClick={onClickOptions}>
              <Svg idIcon="icOptions" width={16} height={16} />
            </button>
          </div>
        </div>
        <div className={sharedCss.rowVerticalCenter}>
          <span
            className={
              categoryControllerCss.positions
            }>{`${productsLength} позиций`}</span>
          <span
            className={
              categoryControllerCss.stoped
            }>{`${stoped} на стопе`}</span>
        </div>
      </div>
      <ContextMenu id={contentMenuId}>
        <Item id="rename" onClick={onEdit} disabled={isUpdatePending}>
          <Text size="h3" weight="medium">
            Переименовать
          </Text>
          <Svg
            idIcon="icEdit"
            width={18}
            height={18}
            className={sharedCss.colorBlue}
          />
        </Item>
        <Separator />
        <Item id="delete" onClick={onDelete} disabled={isDeletePending}>
          <Text size="h3" weight="medium" className={sharedCss.colorRed}>
            Удалить
          </Text>
          <Svg
            idIcon="icTrash"
            width={18}
            height={18}
            className={sharedCss.colorRed}
          />
        </Item>
      </ContextMenu>
    </Fragment>
  );
};
