import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const modal = css`
  padding: 0 16px 16px 16px;
`;

const header = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 12px;
`;

const steps = css`
  border: 1px solid;
  border-radius: 16px;
  margin-top: 12px;
  padding: 6px 12px;

  ${theming((theme) => ({ borderColor: theme.border.primary }))}
`;

const step = css`
  padding: 12px 0;

  &:not(:last-child) {
    border-bottom: 1px solid;
    ${theming((theme) => ({ borderBottomColor: theme.border.primary }))}
  }
`;

const stepTitle = css`
  margin-bottom: 5px;
`;

const channel = css`
  text-decoration: underline;
  ${theming((theme) => ({ color: theme.text.accent }))}
`;

const toast = css`
  z-index: 1 !important;
`;

export const telegramInstructionModalCss = {
  modal,
  header,
  steps,
  step,
  stepTitle,
  channel,
  toast
};
