import { createEffect, createEvent, createStore, sample } from 'effector';
import { IStatistics } from 'shared/types/api/statistics';
import { statisticsApi } from '../api';
import { TGetStatsFx } from '../types';
import { servicesModel } from 'entities/Services/model';

const reset = createEvent();

const $data = createStore<IStatistics>({
  revenue: [],
  views: [],
  orders: [],
}).reset(reset);

const getStats = createEvent();
const getStatsFx = createEffect<TGetStatsFx>(statisticsApi.getStatistics);

const $isPending = getStatsFx.pending;

sample({
  clock: getStats,
  source: servicesModel.selectedService.stores.$service,
  fn: (service) => service.id,
  target: getStatsFx,
});

sample({
  clock: getStatsFx.doneData,
  fn: (res) => res.data,
  target: $data,
});

export const stats = {
  stores: {
    $data,
  },
  events: {
    getStats,
    reset,
  },
  pendings: {
    $isPending,
  },
};
