import { theming } from 'shared/theme';
import { css } from '@linaria/core';
import { setAlpha } from 'shared/theme/lib/setAlpha';

export const labelClass = css`
  font-size: 16px;
  line-height: 26px;
`;

export const wrapperClass = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  &:active {
    .${labelClass} {
      opacity: 0.7;
    }
  }
`;

export const svgWrapperClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${theming((theme) => ({
    background: theme.checkbox.active.background,
  }))};

  svg {
    ${theming((theme) => ({
      color: `${theme.colors.white} !important`,
    }))}
  }
`;

export const checkedIconClass = css`
  width: 16px;
  height: 16px;
`;

export const checkMinusIconClass = css`
  width: 24px;
  height: 24px;
`;

export const checkboxClass = css`
  margin-right: 10px;
  min-width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;

  ${theming((theme) => ({
    boxShadow: theme.commonShadow,
  }))}

  &[data-size='lg'] {
    width: 30px;
    height: 30px;

    .${checkedIconClass} {
      width: 18px;
      height: 18px;
    }
  }

  &[data-size='md'] {
    width: 24px;
    height: 24px;

    .${checkedIconClass} {
      width: 16px;
      height: 16px;
    }
  }

  &[data-size='sm'] {
    width: 20px;
    height: 20px;

    .${checkedIconClass} {
      width: 12px;
      height: 12px;
    }
  }

  &[data-is-rounded='true'] {
    border-radius: 50px;
  }

  &[data-has-border='true'] {
    border: 1px solid;

    ${theming((theme) => ({
      background: setAlpha(theme.checkbox.active.background, 0.2),
      borderColor: theme.checkbox.active.borderColor,
    }))}
  }
`;

export const inputClass = css`
  display: none;

  & + .${checkboxClass} .${svgWrapperClass} {
    transform: scale(0);
    transition: 0.1s linear;
  }

  &:checked + .${checkboxClass} {
    ${theming((theme) => ({ borderColor: theme.checkbox.active.borderColor }))}
  }

  &:checked + .${checkboxClass} .${svgWrapperClass} {
    transform: scale(1);
  }

  &[data-is-secondary='true']:checked + .${checkboxClass} {
    box-shadow: none;
  }
`;
