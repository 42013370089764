import { createEffect, createEvent, createStore, sample } from 'effector';
import { productsApi } from '../api';
import {
  IGetProductsParams,
  TGetProductsFx,
} from '../types';
import { IProduct } from 'shared/types/api/products';
import { servicesModel } from 'entities/Services/model';

const resetAll = createEvent();

const $list = createStore<IProduct[]>([]).reset(resetAll);

const getProducts = createEvent<IGetProductsParams>();
const getProductsFx = createEffect<TGetProductsFx>(productsApi.getProducts);

const $isPending = getProductsFx.pending;

sample({
  clock: getProducts,
  source: {
    shop: servicesModel.selectedService.stores.$service,
    list: $list,
  },
  filter: ({ list, shop }, params) =>
    Boolean(shop && !list.length) || Boolean(params?.isForce),
  fn: ({ shop }) => shop.id,
  target: getProductsFx,
});

sample({
  clock: getProductsFx.doneData,
  fn: (res) => res.data,
  target: $list,
});

const pushProduct = createEvent<IProduct>();

sample({
  clock: pushProduct,
  source: $list,
  filter: (_, newProduct) => Boolean(newProduct),
  fn: (list, newProduct) => [...list, newProduct],
  target: $list,
});

const updateProduct = createEvent<IProduct>();

sample({
  clock: updateProduct,
  source: $list,
  filter: (list, updatedProduct) => Boolean(updatedProduct && list?.length),
  fn: (list, updatedProduct) =>
    list.map((item) => (item.id === updatedProduct.id ? updatedProduct : item)),
  target: $list,
});

const removeProduct = createEvent<string>();

sample({
  clock: removeProduct,
  source: $list,
  filter: (list, productId) => Boolean(productId && list?.length),
  fn: (list, productId) => list.filter((item) => item.id !== productId),
  target: $list,
});

const removeProductsByCategoryId = createEvent<string>();

sample({
  clock: removeProductsByCategoryId,
  source: $list,
  filter: (list, categoryId) => Boolean(categoryId && list?.length),
  fn: (list, categoryId) => list.filter((item) => item.category_id !== categoryId),
  target: $list,
});

export const products = {
  stores: {
    $list,
  },
  events: {
    getProducts,
    pushProduct,
    updateProduct,
    removeProduct,
    removeProductsByCategoryId,
    resetAll,
  },
  pendings: {
    $isPending,
  },
};
