import React from 'react';
import { endPoints } from 'shared/config/endPoints';
import { Orders } from 'pages/Orders';
import { IRoute } from '../types';
import { Navigate } from 'react-router-dom';
import { Order } from 'pages/Order';

export const ordersRoutes: IRoute[] = [
  {
    index: true,
    path: endPoints.ORDERS,
    element: <Orders />,
  },
  {
    path: endPoints.ORDER,
    element: <Order />,
  },
  {
    path: '*',
    element: <Navigate to={`${endPoints.ORDERS}`} />,
  },
];
