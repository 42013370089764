import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  border-radius: 12px;
  padding: 16px 12px 24px 12px;
  display: flex;
  flex-direction: column;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}
`;

const header = css`
  margin-bottom: 12px;
`;

const headerActionButton = css`
  font-size: 14px;
  font-weight: 400;

  ${theming((theme) => ({ color: theme.colors.blue }))}
`;

export const boxCss = {
  container,
  header,
  headerActionButton
};
