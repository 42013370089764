import React, { FC } from 'react';
import { Text } from '../../atoms/Text';
import { INoDataProps } from './types';
import { noDataCss } from './style';
import { Svg } from 'shared/ui/atoms/Svg';

export const NoData: FC<INoDataProps> = ({
  className = '',
  title = 'Нету данных',
  subtitle = 'Они появяться здесь',
}) => {
  return (
    <div className={`${noDataCss.container} ${className ?? ''}`}>
      <Svg idIcon="icNoData" width={56} height={56} color="disabled" />
      <Text size="h3" color="secondary">
        {title}
      </Text>
      {subtitle && <Text color="secondary">{subtitle}</Text>}
    </div>
  );
};
