import { useEffect } from 'react';
import { ITheme } from 'shared/types/theme';

export const useMetaThemeColor = ({ theme, mode }: ITheme) => {
  useEffect(() => {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute('content', theme.background.bg1);
    document.querySelector('html').style.backgroundColor = theme.background.bg1;
    const bodyEl = document.querySelector('body');
    bodyEl.classList.add(mode);
  }, [theme]);
};
