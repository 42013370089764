import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  padding-left: 12px;
  padding-bottom: 12px;

  &:active {
    opacity: 0.7;
  }
`;

const chevronIcon = css`
  transition: 200ms;
  transform: rotate(-90deg);
`;

const button = css`
  min-width: 35px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    ${theming((theme) => ({ color: theme.colors.blue }))}
  }

  &[data-is-expanded='true'] {
    .${chevronIcon} {
      transform: rotate(-270deg);
    }
  }
`;

const positions = css`
  padding: 2px 12px;
  border-radius: 100px;
  font-size: 11px;
  margin-right: 4px;

  ${theming((theme) => ({
    backgroundColor: theme.background.bg1,
    color: theme.text.secondary,
  }))}
`;

const stoped = css`
  padding: 2px 12px;
  border-radius: 100px;
  font-size: 11px;

  ${theming((theme) => ({
    backgroundColor: setAlpha(theme.colors.red, 0.1),
    color: theme.colors.red,
  }))}
`;

const input = css`
  font-size: 17px;
  font-weight: 600;
  flex: 1;

  &::placeholder {
    ${theming((theme) => ({
      color: theme.input.placeholderColor,
    }))}
  }

  ${theming((theme) => ({
    color: theme.text.primary,
  }))}
`;

const spinner = css`
  width: auto;
  margin-right: 10px;
`;

export const categoryControllerCss = {
  container,
  chevronIcon,
  button,
  positions,
  stoped,
  input,
  spinner
};
