import { createEffect, createEvent, createStore, sample } from 'effector';
import { productsApi } from 'entities/Products/api';
import { servicesModel } from 'entities/Services/model';
import { products } from './products';
import { TRemoveProductFx } from '../types';

const remove = createEvent<string>();
const removeFx = createEffect<TRemoveProductFx>(productsApi.deleteProduct);

const $isPending = removeFx.pending;

sample({
  clock: remove,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, productId) => ({
    shopId: service.id,
    productId,
  }),
  target: removeFx,
});

sample({
  clock: removeFx.done,
  fn: ({ params }) => params.productId,
  target: products.events.removeProduct,
});

const $pendingIds = createStore<string[]>([]);

sample({
  clock: remove,
  source: $pendingIds,
  fn: (pendings, productId) => [...pendings, productId],
  target: $pendingIds,
});

sample({
  clock: removeFx.done,
  source: $pendingIds,
  fn: (pendings, { params }) =>
    pendings.filter((productId) => productId !== params.productId),
  target: $pendingIds,
});

export const removeProduct = {
  events: {
    remove,
  },
  effects: {
    removeFx,
  },
  pendings: {
    $isPending,
    $pendingIds,
  },
};
