import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css``;

const content = css`
  border-radius: 12px;
  padding: 6px 12px 24px 12px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  ${theming((theme) => ({
    backgroundColor: theme.background.bg2,
    boxShadow: theme.commonShadow3,
  }))}
`;

const progress = css`
  width: 28px;
  height: 28px;
`;

const headerTitle = css`
  flex: 1;
  margin-inline: 12px;
`;

const headerImg = css`
  background-color: transparent !important;
  max-width: 63px;
  margin-right: -10px;
`;

const headerImgAnimated = css`
  animation: shake 3s;
  animation-fill-mode: towards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes shake {
    0% {
      transform: rotate(0);
    }
    33.3% {
      transform: rotate(-10deg) scale(1.3);
    }
    66.6% {
      transform: rotate(10deg) scale(1);
    }
    99.9% {
      transform: rotate(0);
    }
  }
`;

const points = css`
  display: flex;
  flex-direction: column;
`;

const point = css`
  padding: 10px 0;
  display: flex;
  align-items: center;
`;

const pointName = css`
  flex: 1;
  margin-right: 8px;
`;

const pointNameActive = css`
  text-decoration: line-through;
  opacity: 0.7;
`;

const pointButton = css`
  min-width: 79px;
`;

const spinner = css`
  flex: 0;
`;

const checkIcon = css`
  ${theming((theme) => ({ color: theme.colors.blue }))}
`;

const publicButton = css`
  margin-top: 12px;
`;

const imgTemp = css`
  margin: 0 -20px;
`;

export const checklistCss = {
  container,
  content,
  progress,
  headerTitle,
  headerImg,
  headerImgAnimated,
  points,
  point,
  pointName,
  pointNameActive,
  pointButton,
  spinner,
  checkIcon,
  publicButton,
  imgTemp,
};
