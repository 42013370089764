import { actions } from './actions';
import { cart } from './cart';
import { list } from './list';
import { order } from './order';

export const ordersModel = {
  cart,
  list,
  order,
  actions,
};
