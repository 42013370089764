import React, { FC, useEffect } from 'react';
import { useGetScrollInfo } from 'shared/lib/useGetScrollInfo';
import { Text } from 'shared/ui/atoms/Text';
import { CloseAppButton } from 'shared/ui/atoms/CloseAppButton';
import { headerCss } from './style';
import { IHeaderProps } from './types';
import { useUnit } from 'effector-react';
import { headerModel } from './model';

export const Header: FC<IHeaderProps> = ({
  scrollEl = null,
  hasClose = false,
  title = '',
  rightContent,
  leftContent,
  isAnimatedTitle = false,
  onClickClose
}) => {
  const { direction, scrollY } = useGetScrollInfo(scrollEl);
  const isMinimized = useUnit(headerModel.stores.$isMinimized);

  useEffect(() => {
    if (scrollY > 50) {
      if (direction === 'down') {
        headerModel.events.setIsMinimized(true);
      }
      if (direction === 'up') {
        headerModel.events.setIsMinimized(false);
      }
    }
  }, [scrollY]);

  return (
    <div
      className={headerCss.container}
      {...(isAnimatedTitle && { ['data-is-minimized']: isMinimized })}>
      {leftContent ?? (
        <div>
          {hasClose && <CloseAppButton onClick={onClickClose} />}
          {title && (
            <div
              className={headerCss.titleBox}
              data-is-animated={isAnimatedTitle}
              data-has-close={hasClose}>
              <Text size={isMinimized ? 'h2' : 'h1'}>{title}</Text>
            </div>
          )}
        </div>
      )}
      {rightContent}
    </div>
  );
};
