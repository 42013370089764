import { createEffect, createEvent, createStore, sample } from 'effector';
import { fetchImportDoc } from '../api';
import { servicesModel } from 'entities/Services/model';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';
import { productsModel } from 'entities/Products/model';
import { TImportDocFx } from '../types';

const resetFile = createEvent();
const setFile = createEvent<File>();
const $file = createStore<File>(null).reset(resetFile);

sample({
  clock: setFile,
  target: $file,
});

const importDoc = createEvent();
const importDocFx = createEffect<TImportDocFx>(async ({ file, shopId }) => {
  const formData = new FormData();
  formData.append('type', 'csv_file');
  formData.append('skip_headers', 'true');
  formData.append('data', file);
  return await fetchImportDoc(shopId, formData);
});

const $isImportPending = importDocFx.pending;

sample({
  clock: importDoc,
  source: {
    service: servicesModel.selectedService.stores.$service,
    file: $file,
  },
  fn: ({ service, file }) => ({
    shopId: service.id,
    file,
  }),
  target: importDocFx,
});

sample({
  clock: importDocFx.doneData,
  fn: () => ({ isForce: true }),
  target: [
    productsModel.products.events.getProducts,
    productsModel.categories.events.getCategories,
    resetFile,
  ],
});

sample({
  clock: importDocFx.doneData,
  filter: (res) => Boolean(!res.data.hasError.length && res.data.result),
  fn: (res): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'importCsv.success',
      translateParams: {
        value: res.data.importedItems.toString(),
      },
    },
  }),
  target: showToastEvent,
});

sample({
  clock: importDocFx.doneData,
  filter: (res) => Boolean(res.data.hasError.length || !res.data.result),
  fn: (res) => ({
    toastParams: {
      type: EToastType.Error,
      messageKey: res?.data?.message || 'importCsv.hasError',
    },
  }),
  target: showToastEvent,
});

export const file = {
  stores: {
    $file,
  },
  events: {
    setFile,
    importDoc,
  },
  pendings: {
    $isImportPending,
  },
};
