import { css } from '@linaria/core';

const container = css`
  display: flex;
  flex-direction: column;
`;

const fileUploader = css`
  margin-top: 6px;
`;

const subtitle = css`
  margin-top: 8px;
`;

export const backgroundImageCss = {
  container,
  fileUploader,
  subtitle,
};
