import { createForm } from 'effector-forms';
import { IBaseInfoFormFields } from '../types';
import { createEvent, sample } from 'effector';
import { servicesModel } from 'entities/Services/model';

const initForm = createEvent();

const $form = createForm<IBaseInfoFormFields>({
  fields: {
    name: {
      init: '',
    },
    support_phone: {
      init: '',
    },
  },
  validateOn: ['change', 'submit'],
});

sample({
  clock: initForm,
  source: servicesModel.selectedService.stores.$service,
  fn: (service) => ({
    name: service.name,
    support_phone: service.support_phone,
  }),
  target: $form.setInitialForm,
});

sample({
  clock: $form.formValidated,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, fields) => ({
    ...service,
    name: fields.name,
    support_phone: fields.support_phone,
  }),
  target: servicesModel.update.events.updateService,
});

export const baseInfoForm = {
  stores: {
    $form,
  },
  events: {
    initForm,
  },
};
