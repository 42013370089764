export const getTimeSuffix = (value: number, type: 'hour' | 'minute') => {
  const time = Math.abs(value) % 100;
  if (time >= 11 && time <= 19) {
    return type === 'hour' ? `${value} часов` : `${value} минут`;
  }

  switch (time % 10) {
    case 1:
      return type === 'hour' ? `${value} час` : `${value} минута`;
    case 2:
    case 3:
    case 4:
      return type === 'hour' ? `${value} часа` : `${value} минуты`;
    default:
      return type === 'hour' ? `${value} часов` : `${value} минут`;
  }
};
