import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const button = css`
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const buttonActive = css`
  svg,
  p {
    ${theming((theme) => ({ color: `${theme.colors.blue1} !important` }))}
  }
`;

export const tabCss = {
  button,
  buttonActive,
};
