import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  margin-top: 16px;
`;

const content = css`
  padding: 8px 12px 12px 12px;
  border-radius: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}
`;

const header = css`
  margin-bottom: 12px;
`;

const headerLeft = css`
  flex: 1;
`;

const status = css`
  font-size: 11px;
  border-radius: 40px;
  padding: 2.5px 12px;

  ${theming((theme) => ({
    backgroundColor: theme.background.bg1,
    color: theme.text.secondary,
  }))}

  &[data-is-uploaded='true'] {
    ${theming((theme) => ({
      backgroundColor: setAlpha(theme.colors.green, 0.2),
      color: theme.colors.green,
    }))}
  }
`;

const downloadPattern = css`
  ${theming((theme) => ({
    color: theme.text.accent,
  }))}

  &:active {
    opacity: 0.7;
  }
`;

const importButton = css`
  margin-block: 6px;
`;

export const importCss = {
  container,
  content,
  header,
  headerLeft,
  status,
  downloadPattern,
  importButton,
};
