import { useLocation, useNavigate } from 'react-router-dom';
import { useGate, useUnit } from 'effector-react';
import { useEffect } from 'react';
import { $replaceRoute, $route, HistoryGate, resetRoute } from '../redirect';

export const useRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, replaceRoute] = useUnit([$route, $replaceRoute]);
  useGate(HistoryGate, location);

  useEffect(() => {
    if (path) {
      navigate(path);
      resetRoute();
    }
  }, [path]);

  useEffect(() => {
    if (replaceRoute) {
      navigate(replaceRoute, { replace: true });
    }
  }, [replaceRoute]);
};
