import { css } from '@linaria/core';

const container = css`
  padding-top: 60px;
`;

const content = css`
  padding: 0 16px 86px 16px;
`;

const imgTemp = css`
  margin: 0 -20px;
`;

export const homeCss = {
  container,
  content,
  imgTemp,
};
