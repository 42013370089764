import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  border-radius: 12px;
  padding: 18px 12px 24px 12px;
  margin-top: 24px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}
`;

const info = css`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const divider = css`
  margin-block: 12px;
  height: 1px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg1 }))}
`;

export const productsCss = {
  container,
  info,
  divider,
};
