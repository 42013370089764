import { Rule } from 'effector-forms';
import { ONLY_NUMBERS, REQUIRED } from 'shared/config/regexp';

export const rules = {
  required: function <T = any>(text?: string): Rule<T> {
    return {
      name: 'required',
      validator: (value) => ({
        isValid: Boolean(value),
        errorText: text || REQUIRED,
      }),
    };
  },
  onlyNumbers: () => ({
    name: 'onlyNumbers',
    validator: (value: string) => {
      return {
        isValid: new RegExp(ONLY_NUMBERS).test(value) || value === '',
        errorText: 'Allowed symbols [0-9 ]',
      };
    },
  }),
};
