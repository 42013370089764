import React, { FC } from 'react';

import defaultSprite from 'shared/assets/icons/defaultSprite.svg?url';
import toastSprite from 'shared/assets/icons/toastSprite.svg?url';

import { WrapperSvg } from './style';
import { ESpriteName, ISvg } from './types';

const getCurrentSprite = (spriteName: keyof typeof ESpriteName) => {
  switch (spriteName) {
    case ESpriteName.toast:
      return toastSprite;
    default:
      return defaultSprite;
  }
};

export const Svg: FC<ISvg> = ({
  idIcon,
  spriteName = ESpriteName.default,
  className = '',
  width,
  height,
  id,
  testId,
  color = 'default',
  onClick,
}) => {
  return (
    <WrapperSvg
      id={id}
      data-cy={testId}
      onClick={onClick}
      className={`svg-icon ${className}`}
      width={width}
      height={height}
      data-color={color}>
      <use xlinkHref={`${getCurrentSprite(spriteName)}#${idIcon}`} />
    </WrapperSvg>
  );
};
