import { css } from '@linaria/core';

const container = css`
  display: flex;
  flex-direction: column;
`;

const map = css`
  aspect-ratio: 2;
  border-radius: 16px;
  overflow: hidden;
  pointer-events: none;
  margin-bottom: 12px;
`;

const addressBox = css`
  margin-bottom: 12px;
`;

const address = css`
  margin-left: 4px;
`;

const locationBtn = css`
  width: 100% !important;
  min-height: 34px !important;
`;

export const locationCss = {
  container,
  map,
  addressBox,
  address,
  locationBtn
};
