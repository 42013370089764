import { css } from '@linaria/core';

const container = css`
  padding-top: 60px;
`;

const content = css`
  padding: 0 16px 80px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const scrollContent = css`
  overflow-y: auto;
  overflow-x: hidden;
`;

const backButton = css`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -12px;

  &:active {
    opacity: 0.7;
  }
`;

const buttons = css`
  padding-block: 12px;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  gap: 10px;
`;

const tags = css`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
`;

export const orderCss = {
  container,
  content,
  scrollContent,
  backButton,
  buttons,
  tags,
};
