import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const titleBox = css`
  transition: 200ms;
  min-height: 36px;
  display: flex;
  align-items: center;

  &[data-has-close='true'] {
    margin-top: 10px;
  }
`;

const container = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 16px;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${theming((theme) => ({ backgroundColor: theme.background.bg1 }))}

  &[data-is-minimized='true'] {
    .${titleBox} {
      margin-top: -35px;
      margin-left: 50px;
    }
  }

  &[data-is-minimized='false'] {
    ${titleBox} {
      margin-top: 0;
      margin-left: 0;
    }
  }
`;

export const headerCss = {
  container,
  titleBox,
};
