import React, { lazy } from 'react';
import { endPoints } from 'shared/config/endPoints';
import { AuthElement } from '../ui/AuthElement';
import { tabRoutes } from './tabRoutes';

const CreateService = lazy(() =>
  import('pages/CreateService').then(({ CreateService }) => ({
    default: CreateService,
  })),
);

const MyShops = lazy(() =>
  import('pages/MyShops').then(({ MyShops }) => ({
    default: MyShops,
  })),
);

export const routes = [
  {
    path: endPoints.MY_SHOPS,
    element: <MyShops />,
  },
  {
    path: endPoints.CREATE,
    element: <CreateService />,
  },
  {
    path: endPoints.DEFAULT_PAGE,
    element: <AuthElement />,
    children: tabRoutes,
  },
];
