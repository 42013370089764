import React, { FC } from 'react';
import { Accordion } from 'shared/ui/molecules/Accordion';
import { CategoryController } from '../CategoryController';
import { Products } from '../Products';
import { ICategoryProps } from './types';
import { categoryCss } from './style';

export const Category: FC<ICategoryProps> = ({ data }) => {
  return (
    <Accordion
      className={categoryCss.container}
      init={!data?.isNew}
      isDisabled={data?.isNew}
      controllerElement={({ isExpanded, isDisabled }) => (
        <CategoryController
          data={data}
          isExpanded={isExpanded}
          isDisabled={isDisabled}
        />
      )}>
      <Products data={data}  />
    </Accordion>
  );
};
