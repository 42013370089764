const GET_JWT_TOKEN = `/click-business/auth`;
const GET_BUSINESS_SERVICES = '/click-business/services';

// Shops
const GET_SERVICES = '/services';
const GET_SERVICE = (id: string) => `/services/${id}`;
const CREATE_SERVICE = `/services`;
const UPDATE_SERVICE = (id: string) => `/services/${id}`;
const DELETE_SHOP = (id: string) => `/services/${id}/delete`;
const UPDATE_SERVICE_ADDRESS = (id: string) => `/services/${id}/update-address`;
const UPDATE_SERVICE_CHANNEL = (id: string) => `/services/${id}/update-channel`;
const UPDATE_SERVICE_STATUS = (id: string) => `/services/${id}/update-status`;
const UPDATE_SERVICE_BACKGROUND = (id: string) =>
  `/services/${id}/upload-background`;
const UPDATE_SERVICE_SCHEDULE = (id: string) =>
  `/services/${id}/update-schedule`;

// Products
const GET_PRODUCTS = (shopId: string) => `/products/${shopId}`;
const GET_PRODUCT = (shopId: string, id: string) => `/products/${shopId}/${id}`;
const CREATE_PRODUCT = (shopId: string) => `/products/${shopId}/create`;
const UPDATE_PRODUCT = (shopId: string, id: string) =>
  `/products/${shopId}/${id}/update`;
const DELETE_PRODUCT = (shopId: string, id: string) =>
  `/products/${shopId}/${id}/delete`;
const UPDATE_STATUS_PRODUCT = (shopId: string, id: string) =>
  `/products/${shopId}/${id}/update-status`;

// Categories
const CATEGORIES = (shopId: string) => `/categories/${shopId}`;
const CREATE_CATEGORY = (shopId: string) => `/categories/${shopId}`;
const UPDATE_CATEGORY = (shopId: string, id: string) =>
  `/categories/${shopId}/${id}`;
const DELETE_CATEGORY = (shopId: string, id: string) =>
  `/categories/${shopId}/${id}/delete`;

//Import CSV
const IMPORT_CSV = (shopId: string) => `/products/${shopId}/import`;
const GET_CSV_TEMPLATE = `/templates/products.csv`;

// Orders
const GET_ORDERS = (shopId: string) => `/orders/${shopId}/list?`;
const GET_CART_LIST = (shopId: string) => `/orders/${shopId}/cart`;
const GET_ORDER = (shopId: string, id: string) => `/orders/${shopId}/${id}`;
const UPDATE_ORDER_STATUS = (shopId: string, id: string) =>
  `/orders/${shopId}/${id}/update-status`;

// Statistics
const GET_STATISTICS = (shopId: string) => `/statistic/${shopId}`;

export {
  GET_JWT_TOKEN,
  GET_BUSINESS_SERVICES,
  GET_SERVICE,
  GET_SERVICES,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SHOP,
  UPDATE_SERVICE_ADDRESS,
  UPDATE_SERVICE_CHANNEL,
  UPDATE_SERVICE_STATUS,
  UPDATE_SERVICE_BACKGROUND,
  UPDATE_SERVICE_SCHEDULE,
  GET_PRODUCTS,
  GET_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_STATUS_PRODUCT,
  CATEGORIES,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  IMPORT_CSV,
  GET_CSV_TEMPLATE,
  GET_ORDER,
  GET_CART_LIST,
  GET_ORDERS,
  UPDATE_ORDER_STATUS,
  GET_STATISTICS
};
