import BigNumber from 'bignumber.js';

export const nFormatter = (value) => {
  // Create a BigNumber instance from the input value
  const bigValue = new BigNumber(value);

  // Define the suffixes for different scales
  const suffixes = ['', 'K', 'M', 'B', 'T'];

  // Determine the scale (thousands, millions, etc.)
  let scaleIndex = 0;

  // Loop through suffixes until we find the appropriate scale
  let scaledValue = bigValue;
  while (scaledValue.gte(1000) && scaleIndex < suffixes.length - 1) {
    scaledValue = scaledValue.dividedBy(1000);
    scaleIndex++;
  }

  // Format the value with one decimal point
  const formattedValue = scaledValue.toFixed(1);

  // Return the formatted string with the appropriate suffix
  return `${formattedValue}${suffixes[scaleIndex]}`;
};
