import { createEffect, createEvent, createStore, sample } from 'effector';
import { endPoints } from 'shared/config/endPoints';
import { redirect, replace } from 'shared/lib/redirect';
import { TMerchantInfo, TGetMerchantInfoFx } from '../types';
import { LOCAL_STORAGE_MERCHANT_INFO } from 'shared/config/constants';
import { authApi } from '../api';
import { spread } from 'patronum';
import { servicesModel } from 'entities/Services/model';

const resetAll = createEvent();

const setMerchantInfo = createEvent<TMerchantInfo>();
const $merchantInfo = createStore<TMerchantInfo>(
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_MERCHANT_INFO)) ?? null,
);

const cacheMerchantInfoFx = createEffect<TMerchantInfo, void>((value) => {
  localStorage.setItem(LOCAL_STORAGE_MERCHANT_INFO, JSON.stringify(value));
});

sample({
  clock: setMerchantInfo,
  target: [cacheMerchantInfoFx, $merchantInfo],
});

const getMerchantInfo = createEvent<string>();
const getMerchantInfoFx = createEffect<TGetMerchantInfoFx>(
  authApi.fetchJwtToken,
);
// const getTokenFx = createEffect<TGetTokenFx>(() =>
//   API().post('/merchants', {
//     name: `Merchant 12345`,
//     merchant_id: '12345',
//     merchant_user_id: '12345',
//   }),
// );

sample({
  clock: getMerchantInfo,
  target: getMerchantInfoFx,
});

sample({
  clock: getMerchantInfoFx.doneData,
  fn: (res) => res.data,
  target: setMerchantInfo,
});

sample({
  clock: getMerchantInfoFx.doneData,
  fn: (res) => res.data,
  target: $merchantInfo,
});

sample({
  clock: $merchantInfo,
  filter: Boolean,
  fn: () => ({
    redirectTo: { pathname: endPoints.MY_SHOPS, search: '' },
    openWelcomeModal: true,
  }),
  target: spread({
    targets: {
      redirectTo: replace,
      openWelcomeModal: servicesModel.welcomeModal.setVisible,
    },
  }),
});

sample({
  clock: resetAll,
  source: $merchantInfo,
  fn: (info) => endPoints.LOGIN.replace(':merchant_id', info.merchant_id),
  target: redirect,
});

sample({
  clock: resetAll,
  fn: () => null,
  target: $merchantInfo,
});

export const creds = {
  stores: {
    $merchantInfo,
  },
  events: {
    setMerchantInfo,
    getMerchantInfo,
    resetAll,
  },
};
