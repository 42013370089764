import { css } from '@linaria/core';

const container = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
`;

const controller = css`
  width: 100%;
`;

const content = css`
  width: 100%;
  transition: 300ms;
`;

export const accordionCss = {
  container,
  controller,
  content,
};
