import { css } from '@linaria/core';

const container = css`
  margin-top: 24px;
`;

const allTitle = css`
  margin-right: 6px;
`;

const list = css`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const arrowIcon = css`
  transform: rotate(-180deg);
`;

const link = css`
  display: flex;
  align-items: center;

  &:active {
    opacity: 0.7;
  }
`;

export const ordersCss = {
  container,
  allTitle,
  list,
  arrowIcon,
  link,
};
