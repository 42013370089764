import { theming } from 'shared/theme';
import { css } from '@linaria/core';

const label = css`
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  ${theming((theme) => ({ color: theme.text.primary }))}
`;

const input = css`
  width: 100%;
  height: 44px;
  appearance: none;
  border: 1px solid;
  font-size: 17px;
  font-weight: 400;
  padding: 0 12px;
  border-radius: 8px;
  transition: all 0.25s ease-in-out;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 200000s ease-in-out 200000s, color 0s 200000s;
  }

  ${theming((theme) => ({
    caretColor: theme.input.textColor,
    color: theme.input.textColor,
    borderColor: theme.input.default.borderColor,
    background: theme.input.default.background,
  }))}

  &::placeholder {
    ${theming((theme) => ({
      color: theme.input.placeholderColor,
    }))}
  }

  &:hover {
    ${theming((theme) => ({
      borderColor: theme.input.hover.borderColor,
      background: theme.input.hover.background,
    }))}
  }

  &:focus {
    ${theming((theme) => ({
      borderColor: theme.input.focus.borderColor,
      background: theme.input.focus.background,
    }))}
  }

  &:disabled {
    //TODO переделать, когда в ките появится
    opacity: 0.7;
  }

  &[data-is-error='true'] {
    ${theming((theme) => ({
      borderColor: theme.input.error.borderColor,
      background: theme.input.error.background,
    }))}
  }
`;

const inputWrapper = css`
  position: relative;
`;

const leftContentWrapper = css`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const rightContentWrapper = css`
  position: absolute !important;
  right: 15px !important;
  top: -2px !important;
  height: fit-content;
  margin: auto;
  bottom: 0;
`;

export const inputCss = {
  label,
  input,
  inputWrapper,
  leftContentWrapper,
  rightContentWrapper,
};
