import { createEffect, createEvent, sample } from 'effector';
import { TUpdateServiceFx } from '../types';
import { servicesApi } from '../api';
import { selectedService } from './selectedService';
import { services } from './services';
import { IService } from 'shared/types/api/services';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const updateService = createEvent<IService>();
const updateServiceFx = createEffect<TUpdateServiceFx>(
  servicesApi.updateService,
);

const $isPending = updateServiceFx.pending;

sample({
  clock: updateService,
  source: selectedService.stores.$service,
  fn: (service, params) => ({ shopId: service.id, params }),
  target: updateServiceFx,
});

sample({
  clock: updateServiceFx.doneData,
  fn: (res) => res.data,
  target: [selectedService.events.updateService, services.events.updateService],
});

sample({
  clock: updateServiceFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'Данные витрины обновлены!',
    },
  }),
  target: showToastEvent,
});

export const update = {
  events: {
    updateService,
  },
  pendings: {
    $isPending,
  },
};
