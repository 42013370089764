import { useEffect, useRef, useState } from 'react';

export const useDelayedVisible = (isOpen: boolean, delay = 200) => {
  const [visible, setVisible] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!isOpen)
      timeoutRef.current = setTimeout(() => {
        if (!isOpen) setVisible(false);
      }, delay);
    else {
      clearTimeout(timeoutRef.current);
      if (isOpen) setVisible(true);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isOpen, timeoutRef.current]);

  return { visible };
};
