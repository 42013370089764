import { address } from './address';
import { background } from './background';
import { selectedService } from './selectedService';
import { services } from './services';
import { shopPublishedModal } from './shopPublishedModal';
import { status } from './status';
import { update } from './update';
import { welcomeModal } from './welcomeModal';

export const servicesModel = {
  services,
  selectedService,
  address,
  status,
  background,
  update,
  shopPublishedModal,
  welcomeModal
};
