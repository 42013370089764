import { IService } from './services';

export interface IOrderStatus {
  status: EOrderStatus;
  createdAt: number;
}

export interface IOrderUserInfo {
  client_id: number;
  name: string;
  surname: string;
  phone_number: string;
}

export interface IOrder {
  id: string;
  user_id: string;
  products_amount: number;
  total_sum: number;
  total_sum_format: string;
  merchant_id: string;
  service: IService;
  service_id: string;
  order_preview_id: string;
  status: EOrderStatus;
  statuses: IOrderStatus[];
  products: IOrderProduct[];
  userInfo: IOrderUserInfo;
}

export interface IOrderProduct {
  _id: string;
  name: string;
  description: string;
  price: number;
  image: string;
  category_id: string;
  service_id: string;
  merchant_id: string;
  amount: number;
  total_sum: number;
}

export enum EOrderStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in progress',
  CART = 'cart',
  PAID = 'paid',
  READY = 'ready',
  DONE = 'done',
  CANCELED = 'canceled',
}

export interface IGetOrdersParams {
  shopId: string;
  filters?: {
    status?: string;
  };
}

export interface IGetOrderParams {
  shopId: string;
  id: string;
}

export interface IUpdateOrderStatusParams {
  shopId: string;
  id: string;
  params: {
    status: string;
  };
}
