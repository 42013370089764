import React, { FC } from 'react';
import { buttonCss } from './style';
import { IButton } from './types';
import { SpinnerBlock } from '../SpinnerBlock';

export const Button: FC<IButton> = ({
  className = '',
  styleType = 'primary',
  fontSize = '16',
  size = 'md',
  textColor,
  roundSize = 'm',
  type = 'button',
  id,
  testId,
  isDisabled = false,
  isRounded = false,
  isActive = false,
  withShadow = true,
  children,
  onClick = () => {},
  onMouseOver = () => {},
  onMouseOut = () => {},
  onMouseDown = () => {},
  onMouseUp = () => {},
  onContextMenu = () => {},
  formId = null,
  isLoading = false,
}) => {
  return (
    <button
      form={formId}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onContextMenu={onContextMenu}
      data-is-rounded={isRounded}
      disabled={isDisabled || isLoading}
      id={id}
      data-is-text-color={textColor}
      data-is-style-type={styleType}
      data-is-round-size={roundSize}
      data-is-active={isActive}
      data-font-size={fontSize}
      data-size={size}
      data-with-shadow={withShadow}
      data-cy={testId}
      className={`${buttonCss.wrapper} ${className}`}
      type={type}>
      {isLoading ? <SpinnerBlock size={20} /> : children}
    </button>
  );
};
