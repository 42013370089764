import { createEffect, createEvent, sample } from 'effector';
import { productsApi } from 'entities/Products/api';
import { TCreateProductFx } from '../types';
import { servicesModel } from 'entities/Services/model';
import { productsModel } from 'entities/Products/model';
import { modal } from './modal';

const create = createEvent<FormData>();
const createFx = createEffect<TCreateProductFx>(productsApi.createProduct);

const $isPending = createFx.pending;

sample({
  clock: create,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, formData) => ({ shopId: service.id, params: formData }),
  target: createFx,
});

sample({
  clock: createFx.doneData,
  fn: (res) => res.data,
  target: productsModel.products.events.pushProduct,
});

sample({
  clock: createFx.doneData,
  target: modal.resetModal,
});

export const createProduct = {
  events: {
    create,
  },
  pendings: {
    $isPending,
  },
};
