import React, { FC } from 'react';
import { Svg } from 'shared/ui/atoms/Svg';
import { backButtonCss } from './style';
import { IBackButtonProps } from './types';
import { useNavigate } from 'react-router-dom';

export const BackButton: FC<IBackButtonProps> = ({ className = '' }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate?.(-1);
  };

  return (
    <button
      className={`${backButtonCss.button} ${className}`}
      onClick={onClick}>
      <Svg idIcon="icLeftChevronBold" width={18} height={18} />
    </button>
  );
};
