import React, { FC } from 'react';
import { ordersCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { sharedCss } from 'shared/css';
import { NavLink } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { Svg } from 'shared/ui/atoms/Svg';
import { useUnit } from 'effector-react';
import { ordersModel } from 'entities/Orders/model';
import { Order } from 'entities/Orders/ui/Order';
import { NoData } from 'shared/ui/molecules/NoData';

export const Orders: FC = () => {
  const [orders] = useUnit([ordersModel.list.stores.$orders]);
  const lastTwoOrders = orders.filter((_, index) => index >= orders.length - 2);

  return (
    <div className={ordersCss.container}>
      <div className={sharedCss.rowBetween}>
        <Text size="h2">Последние 2 заказа</Text>
        <NavLink className={ordersCss.link} to={endPoints.ORDERS}>
          <Text className={ordersCss.allTitle}>Все</Text>
          <Svg
            className={ordersCss.arrowIcon}
            idIcon="icLeftChevron"
            width={16}
            height={16}
          />
        </NavLink>
      </div>
      <div className={ordersCss.list}>
        {lastTwoOrders.length ? (
          lastTwoOrders.map((order) => <Order key={order.id} data={order} />)
        ) : (
          <NoData title="У вас отсутствуют заказы" />
        )}
      </div>
    </div>
  );
};
