import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const box = css`
  border-radius: 12px;
  padding: 12px;
  flex: 1;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}
`;

const boxHeader = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  word-break: break-all;
  margin-bottom: 6px;
`;

const row = css`
  display: flex;
  align-items: stretch;
  gap: 12px;
`;

const blueIcon = css`
  ${theming((theme) => ({ color: theme.colors.blue }))}
`;

const greenIcon = css`
  ${theming((theme) => ({ color: theme.colors.green }))}
`;

const profitStats = css`
  height: 90px;
  display: flex;
  align-items: stretch;
  gap: 16px;
  margin-top: 24px;
`;

const profitColumn = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
`;

const profitIndicator = css`
  width: 100%;
  border-radius: 4px;
  min-height: 1px;
  transition: 200ms;

  ${theming((theme) => ({ backgroundColor: setAlpha(theme.colors.blue, 0.3) }))}

  &[data-is-active='true'] {
    ${theming((theme) => ({ backgroundColor: theme.colors.blue }))}
  }
`;

export const availableStatsCss = {
  container,
  box,
  boxHeader,
  row,
  blueIcon,
  greenIcon,
  profitStats,
  profitColumn,
  profitIndicator,
};
