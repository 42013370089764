import { ICoords } from 'shared/types/api/common';

export const LOCAL_STORAGE_MERCHANT_INFO = 'merchant_info';
export const LOCAL_STORAGE_CREDENTIALS = 'credentials';

export const DATE_TIME_FORMAT = 'dd MMMM yyyy HH:mm';

export const defaultMapCenter: ICoords = { lat: 41.311158, lng: 69.279737 };
export const minMapZoom = 1;
export const maxMapZoom = 22;

export const shortDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
