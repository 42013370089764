import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { IOrderProps } from './types';
import { orderCss } from './style';
import { sharedCss } from 'shared/css';
import { Text } from 'shared/ui/atoms/Text';
import { Svg } from 'shared/ui/atoms/Svg';
import { OrderTag } from '../OrderTag';
import BigNumber from 'bignumber.js';
import { DATE_TIME_FORMAT } from 'shared/config/constants';
import { format } from 'date-fns';
import { EOrderStatus } from 'shared/types/api/orders';

export const Order: FC<IOrderProps> = ({ data }) => {
  const productNames = data.products.map((product) => product.name).toString();
  const createdAt = data?.statuses?.find(
    (status) => status.status === EOrderStatus.CREATED,
  )?.createdAt;

  return (
    <NavLink
      to={endPoints.ORDER.replace(':id', data.id)}
      className={orderCss.button}>
      <div className={`${orderCss.header} ${sharedCss.rowBetween}`}>
        <Text size="h3">{`№ ${data?.order_preview_id}`}</Text>
        <div className={sharedCss.rowVerticalCenter}>
          <Text size="h3" weight="medium">
            {`${BigNumber(data.total_sum).toFormat(2)} сум`}
          </Text>
          <Svg idIcon="icLeftChevron" width={16} height={16} />
        </div>
      </div>
      <div className={orderCss.tags}>
        <OrderTag status={data.status} />
        <OrderTag title="Самовывоз" />
      </div>
      <Text color="secondary" className={orderCss.productNames}>
        {productNames}
      </Text>
      <div className={`${orderCss.date} ${sharedCss.rowVerticalCenter}`}>
        <Svg idIcon="icClock" width={18} height={18} />
        <Text color="secondary">{format(createdAt, DATE_TIME_FORMAT)}</Text>
      </div>
    </NavLink>
  );
};
