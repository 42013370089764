import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  margin-top: 12px;
`;

const stats = css`
  border-radius: 16px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  margin-block: 12px;
  position: relative;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}
`;

const titleSkeleton = css`
  height: 12px !important;
  width: 35% !important;
  border-radius: 4px;
  opacity: 0.5;
`;

const profitSkeleton = css`
  height: 12px !important;
  width: 50% !important;
  margin-top: 12px;
  border-radius: 4px;
  opacity: 0.5;
`;

const dayBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 24px;

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const stat = css`
  width: 100% !important;
  border-radius: 4px;
  opacity: 0.5;
`;

const date = css`
  height: 8px !important;
  width: 100% !important;
  border-radius: 4px;
  margin-top: 8px;
  opacity: 0.5;
`;

const overlay = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  svg {
    ${theming((theme) => ({ color: theme.colors.green }))}
  }
`;

const overlayTitle = css`
  margin-top: 12px;
  text-align: center;
`;

export const unavailableStatsCss = {
  container,
  stats,
  titleSkeleton,
  profitSkeleton,
  dayBox,
  stat,
  date,
  overlay,
  overlayTitle,
};
