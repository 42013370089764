import React, { FC } from 'react';
import {
  PictureClassStyle,
  StyledLazyLoadImage,
  StyledPlaceholder,
} from './style';
import { IImage } from './types';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const Image: FC<IImage> = React.memo(
  ({
    className = '',
    wrapperClassName = '',
    src,
    onClick,
    onLoad,
    alt,
    id,
    fit,
    srcWebp,
    visibleByDefault = true,
    effect = 'opacity',
    style,
  }) => {
    return (
      <picture
        className={`${PictureClassStyle} ${className}`}
        data-object-fit={fit}>
        {srcWebp && <source type="image/webp" srcSet={srcWebp} />}
        <StyledLazyLoadImage
          style={style}
          id={id}
          src={src}
          alt={alt}
          effect={effect}
          wrapperClassName={wrapperClassName}
          visibleByDefault={visibleByDefault}
          onClick={onClick}
          onLoad={onLoad}
          placeholder={<StyledPlaceholder />}
        />
      </picture>
    );
  },
);
