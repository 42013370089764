import { css } from '@linaria/core';

const container = css`
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const telegramChannelCss = {
  container,
};
