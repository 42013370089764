import React from 'react';
import { endPoints } from 'shared/config/endPoints';
import { Navigate } from 'react-router-dom';
import { IRoute } from '../types';
import { Shop } from 'pages/Shop';
import { Menu } from 'pages/Menu';
import { ordersRoutes } from './ordersRoutes';
import { OrdersElement } from '../ui/OrdersElement';
import { Home } from 'pages/Home';

export const tabRoutes: IRoute[] = [
  {
    index: true,
    path: endPoints.DEFAULT_PAGE,
    element: <Home />,
  },
  {
    path: endPoints.SHOP,
    element: <Shop />,
  },
  {
    path: endPoints.ORDERS,
    element: <OrdersElement />,
    children: ordersRoutes,
  },
  {
    path: endPoints.MENU,
    element: <Menu />,
  },
  {
    path: '*',
    element: <Navigate to={`${endPoints.DEFAULT_PAGE}`} />,
  },
];
