import { createEvent, createStore, sample } from 'effector';

const setIsMinimized = createEvent<boolean>();
const $isMinimized = createStore<boolean>(false);

sample({
  clock: setIsMinimized,
  target: $isMinimized,
});

export const headerModel = {
  stores: {
    $isMinimized,
  },
  events: {
    setIsMinimized,
  },
};
