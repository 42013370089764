import React, { FC } from 'react';
import { PageContainer } from 'shared/ui/molecules/PageContainer';
import { ordersCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { useUnit } from 'effector-react';
import { ordersModel } from 'entities/Orders/model';
import { EOrderStatus } from 'shared/types/api/orders';
import { Order } from 'entities/Orders/ui/Order';
import { NoData } from 'shared/ui/molecules/NoData';
import BigNumber from 'bignumber.js';
import { Header } from 'widgets/Header';
import { Button } from 'shared/ui/atoms/Button';
import { Svg } from 'shared/ui/atoms/Svg';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';

export const Orders: FC = () => {
  const [orders, isPending] = useUnit([
    ordersModel.list.stores.$orders,
    ordersModel.list.pendings.$isPending,
  ]);
  const activeOrders = orders.filter(
    (order) =>
      order.status !== EOrderStatus.DONE &&
      order.status !== EOrderStatus.CANCELED,
  );
  const doneOrders = orders.filter(
    (order) =>
      order.status === EOrderStatus.DONE ||
      order.status === EOrderStatus.CANCELED,
  );
  const activeOrdersTotalAmount = activeOrders.reduce(
    (prev, acc) => prev + acc.total_sum,
    0,
  );
  const doneOrdersTotalAmount = doneOrders.reduce(
    (prev, acc) => prev + acc.total_sum,
    0,
  );

  const onRefresh = () => {
    ordersModel.list.events.getOrders();
  };

  return (
    <PageContainer className={ordersCss.container}>
      <Header
        scrollEl="orders"
        title="Заказы"
        rightContent={
          <Button
            onClick={onRefresh}
            isRounded
            styleType="tertiary"
            isLoading={isPending}>
            <Svg idIcon="icRepeat" width={24} height={24} />
          </Button>
        }
      />
      {isPending && <SpinnerBlock size={40} />}
      {!isPending && (
        <div id="orders" className={ordersCss.list}>
          <div className={ordersCss.section}>
            <div className={ordersCss.sectionHeader}>
              <Text size="h3" className={ordersCss.sectionTitle}>
                {`Всего: ${BigNumber(activeOrdersTotalAmount).toFormat(2)} сум`}
              </Text>
              <Text color="secondary">{`Активных заказов: ${
                activeOrders?.length ?? 0
              }`}</Text>
            </div>
            <div className={ordersCss.sectionOrders}>
              {activeOrders.length ? (
                activeOrders.map((order) => (
                  <Order key={order.id} data={order} />
                ))
              ) : (
                <NoData title="У вас нет активных заказов" />
              )}
            </div>
          </div>

          <div className={ordersCss.section}>
            <div className={ordersCss.sectionHeader}>
              <Text size="h3" className={ordersCss.sectionTitle}>
                {`Всего: ${BigNumber(doneOrdersTotalAmount).toFormat(2)} сум`}
              </Text>
              <Text color="secondary">{`Завершенных заказов: ${
                doneOrders?.length ?? 0
              }`}</Text>
            </div>
            <div className={ordersCss.sectionOrders}>
              {doneOrders.length ? (
                doneOrders.map((order) => <Order key={order.id} data={order} />)
              ) : (
                <NoData title="У вас нет завершенных заказов" />
              )}
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};
