import { css } from '@linaria/core';
import { theming } from 'shared/theme';

export const toast = css`
  display: flex;
  align-items: center;
`;

export const svg = css`
  margin-right: 15px;
  width: 24px;
  height: 24px;
  ${theming((theme) => ({ color: theme.svg.default }))}
`;

export const message = css`
  flex: 1;
  ${theming((theme) => ({ color: theme.text.primary }))}
`;
