import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const timePeriod = css`
  gap: 12px;
`;

const selectIcon = css`
  transform: rotate(180deg);

  ${theming((theme) => ({ color: theme.colors.blue }))}
`;

const submitBtn = css`
  min-height: 34px !important;
  width: auto !important;
`;

export const workingHoursCss = {
  container,
  timePeriod,
  selectIcon,
  submitBtn,
};
