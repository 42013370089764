import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  padding: 4px 12px 12px 12px; 
`;

const divider = css`
  height: 1px;
  margin-block: 12px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg1 }))}
`;

const addButton = css`
  width: 100% !important;
`;

export const productsCss = {
  container,
  divider,
  addButton,
};
