import { css } from '@linaria/core';

const container = css`
  padding-top: 60px;
`;

const list = css`
  padding: 0 16px 80px 16px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const section = css``;

const sectionHeader = css`
  padding-block: 12px;
`;

const sectionTitle = css``;

const sectionOrders = css`
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ordersCss = {
  container,
  list,
  section,
  sectionHeader,
  sectionTitle,
  sectionOrders,
};
