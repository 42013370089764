import React, { FC, useEffect } from 'react';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { Text } from 'shared/ui/atoms/Text';
import { PageContainer } from 'shared/ui/molecules/PageContainer';
import { sharedCss } from 'shared/css';
import { authCss } from './style';
import { useParams } from 'react-router-dom';
import { authModel } from 'entities/Auth/model';
import { LOCAL_STORAGE_CREDENTIALS } from 'shared/config/constants';

export const Auth: FC = () => {
  const params = useParams();

  useEffect(() => {
    if (params?.web_session) {
      localStorage.setItem(
        LOCAL_STORAGE_CREDENTIALS,
        JSON.stringify({ session: params.web_session }),
      );
    }

    if (params?.merchant_id) {
      authModel.creds.events.getMerchantInfo(params.merchant_id);
    }
  }, [params]);

  return (
    <PageContainer className={sharedCss.rowCenter}>
      <Text size="h3" weight="medium">
        Авторизация ...
      </Text>
      <SpinnerBlock size={40} className={authCss.spinner} />
    </PageContainer>
  );
};
