import { useEffect, useState } from 'react';

let scrolled = 0;
let scrollEndTimer;
let lastScrollPosition = 0;

export const useGetScrollInfo = (scrollEl: HTMLElement | string) => {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [direction, setDirection] = useState<'down' | 'up'>(null);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  useEffect(() => {
    if (scrollEl && typeof scrollEl === 'string') {
      const el = document.getElementById(scrollEl);
      el && getScrollInfo(el);
    }
  }, [scrollEl, scrollTop]);

  useEffect(() => {
    if (scrollEl && typeof scrollEl === 'object') {
      getScrollInfo(scrollEl);
    }
  }, [scrollEl, scrollTop]);

  const getScrollInfo = (el: HTMLElement) => {
    el.onscrollend = () => {
      setScrollTop(el.scrollTop);
    };

    el.onscroll = () => {
      if (
        el.scrollTop + el.clientHeight < el.scrollHeight &&
        el.scrollTop > 0
      ) {
        clearTimeout(scrollEndTimer);

        if (!isScrolling) setIsScrolling(true);

        if (el.scrollTop > scrolled) {
          setDirection('down');
        } else if (el.scrollTop < scrolled) {
          setDirection('up');
        } else {
          setDirection(null);
        }

        scrolled = el.scrollTop;
        setScrollTop(el.scrollTop);

        scrollEndTimer = setTimeout(() => {
          setIsScrolling(false);
          lastScrollPosition = el.scrollTop;
        }, 100);
      }
    };
  };

  return {
    direction,
    scrollTop: Math.abs(scrollTop),
    scrollY: Math.abs(lastScrollPosition - scrollTop),
    isScrolling,
  };
};
