import React, { forwardRef, useEffect, useState } from 'react';
import { customModalCss } from './style';
import Modal from 'react-modal';
import { useUnit } from 'effector-react';
import { ICustomModal, ICustomModalStyles } from './types';
import { themeModel } from 'shared/theme/model';

export const CustomModal = forwardRef<Modal, ICustomModal & ICustomModalStyles>(
  (
    {
      className = '',
      overlayClassName = '',
      isOpen,
      children,
      disableDefaults = false,
      id,
      animation = 'slide',
      isCentered,
      withOverlay = true,
      fullHeight = false,
      onRequestClose,
    },
    ref,
  ) => {
    const [theme] = useUnit([themeModel.stores.$theme]);
    const [visible, setVisible] = useState(null);

    useEffect(() => {
      !isOpen && document.body.classList.remove('ReactModal__Body--open');
      if (animation === 'slide') {
        if (typeof visible === 'boolean' && !isOpen)
          setTimeout(() => {
            setVisible(isOpen);
          }, 600);
        else setVisible(isOpen);
      }
    }, [isOpen]);

    return (
      <Modal
        id={id}
        ref={ref}
        portalClassName={`${theme.mode} ${customModalCss.portal} ${
          isCentered && customModalCss.centered
        } ${animation === 'slide' ? customModalCss.portalSlide : ''}`}
        overlayClassName={`${
          !withOverlay && customModalCss.hideOverlay
        } ${overlayClassName}`}
        isOpen={Boolean(animation === 'slide' ? visible : isOpen)}
        onRequestClose={onRequestClose}
        shouldCloseOnEsc={Boolean(isOpen)}
        className={`${!disableDefaults && customModalCss.defaultModal} ${
          fullHeight && customModalCss.fullHeight
        } ${className} ${
          animation === 'slide'
            ? isOpen
              ? customModalCss.show
              : customModalCss.hide
            : null
        }`}>
        {children}
      </Modal>
    );
  },
);
