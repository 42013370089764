import { LinariaClassName } from '@linaria/core';
import { ToastPosition } from 'react-toastify';

export enum EToastType {
  Error = 'icError',
  Info = 'icInfo',
  Warning = 'icWarning',
  Success = 'icSuccess',
  Secondary = 'icSuccessBig',
  Custom = 'custom',
}

export interface ITranslateParams {
  [key: string]: string;
}

export interface IToast {
  type: EToastType;
  messageText?: string;
  messageKey?: string;
  iconId?: string;
  translateParams?: ITranslateParams;
}

export interface IShowToast {
  autoClose?: number | false;
  toastParams: IToast;
  position?: ToastPosition;
  className?: LinariaClassName;
}

export type TShowToast = (params: IShowToast) => string | number;
