import { createEffect, createEvent, createStore, sample } from 'effector';
import { TUpdateServiceStatusFx } from '../types';
import { servicesApi } from '../api';
import { services } from './services';
import { IUpdateServiceStatusParams } from 'shared/types/api/services';
import { selectedService } from './selectedService';
import { shopPublishedModal } from './shopPublishedModal';

const updateStatus = createEvent<IUpdateServiceStatusParams>();
const updateStatusFx = createEffect<TUpdateServiceStatusFx>(
  servicesApi.updateServiceStatus,
);

const $listOfPendings = createStore<string[]>([]);

sample({
  clock: updateStatus,
  target: updateStatusFx,
});

sample({
  clock: updateStatus,
  source: $listOfPendings,
  fn: (listOfPendings, params) => [...listOfPendings, params.shopId],
  target: $listOfPendings,
});

sample({
  clock: updateStatusFx.doneData,
  fn: (res) => res.data,
  target: services.events.updateService,
});

sample({
  clock: updateStatusFx.doneData,
  source: selectedService.stores.$service,
  filter: (selectedService, res) => Boolean(selectedService.id === res.data.id),
  fn: (_, res) => res.data,
  target: selectedService.events.updateService,
});

sample({
  clock: updateStatusFx.done,
  source: $listOfPendings,
  fn: (listOfPendings, { params }) => {
    return listOfPendings.filter((shopId) => shopId !== params.shopId);
  },
  target: $listOfPendings,
});

sample({
  clock: updateStatusFx.done,
  filter: ({ params }) => params?.isPublish,
  fn: () => true,
  target: shopPublishedModal.setVisible
});

export const status = {
  events: {
    updateStatus,
  },
  pendings: {
    $listOfPendings,
  },
};
