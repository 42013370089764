import React, { FC } from 'react';
import { textCss } from './style';
import { IText } from './types';

export const Text: FC<IText> = ({
  testId,
  children,
  className = '',
  weight,
  title,
  size = '',
  color = 'primary',
  isEmpty = false,
  style
}) => {  
  if (isEmpty)
    return (
      <div
        className={`${textCss.emptyText} ${textCss.text} ${className}`}
        data-size={size}
        style={style}
      />
    );
  return (
    <p
      data-cy={testId}
      className={`${textCss.text} ${className}`}
      title={title}
      data-color={color}
      data-size={size}
      data-weight={weight}
      style={style}>
      {children}
    </p>
  );
};
