import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import { OutputFormat, setDefaults } from 'react-geocode';
import { RouterRoutesElement } from 'processes';
import { checkOnlineBrowserState } from 'shared/model/connection';
import { globalClass, toastContainerClass } from 'shared/theme/GlobalStyles';
import { endPoints } from 'shared/config/endPoints';
import { renderRoutes } from 'processes/Router/lib/renderRoutes';
import { useMetaThemeColor } from './lib/useMetaThemeColor';
import { themeModel } from 'shared/theme/model';
import { useGetCookies } from './lib/useGetCookies';
import { inspect, type Message } from 'effector/inspect';
import { loginRoutes } from 'processes/Router/config/loginRoutes';
import { routes } from 'processes/Router/config/routes';
import { authModel } from 'entities/Auth/model';

import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#root');

setDefaults({
  key: process.env.REACT_APP_GEOCODING_API_KEY,
  language: 'ru',
  outputFormat: OutputFormat.JSON,
});

function logInspectMessage(m: Message) {
  const { name, value } = m;
  if (name === 'failData' && value) {
    console.error('FAIL_DATA', value);
  }
}

inspect({
  fn: (m) => {
    logInspectMessage(m);
  },
});

const App = () => {
  const [activeTheme] = useUnit([themeModel.stores.$theme]);
  useMetaThemeColor(activeTheme);
  useGetCookies();

  useEffect(() => {
    checkOnlineBrowserState();

    return () => {
      authModel.logout.events.logoutAccount();
    };
  }, []);

  return (
    <div className={globalClass}>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        className={toastContainerClass}
        closeButton={false}
      />
      <BrowserRouter>
        <Routes>
          <Route
            path={endPoints.DEFAULT_PAGE}
            element={<RouterRoutesElement />}>
            {renderRoutes([...loginRoutes, ...routes])}
            <Route
              path="*"
              element={<Navigate to={endPoints.DEFAULT_PAGE} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
