import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const show = css`
  animation: slideIn 0.3s;
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(120%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const hide = css`
  animation: slideOut 0.3s forwards;
  @keyframes slideOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(120%);
    }
  }
`;

const portal = css`
  .ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 100ms ease-in-out;

    background: rgba(0, 0, 0, 0.6);
  }

  .ReactModal__Overlay--centered {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

const portalSlide = css`
  .ReactModal__Overlay {
    backdrop-filter: none;
    height: 100%;
  }
`;

const centered = css`
  .ReactModal__Overlay {
    align-items: center;
  }
`;

const defaultModal = css`
  position: relative;
  overflow: auto;
  border-radius: 20px 20px 0 0;
  outline: none !important;
  width: 100vw;
  ${theming((theme) => ({
    background: theme.background.bg2,
    boxShadow: theme.modalShadow,
  }))}
  &:focus-visible {
    outline: 0;
  }
`;

const hideOverlay = css`
  background: transparent !important;
`;

const fullHeight = css`
  height: 100%;
  border-radius: 0;
`;

export const customModalCss = {
  portal,
  portalSlide,
  centered,
  hideOverlay,
  show,
  hide,
  defaultModal,
  fullHeight,
};
