import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { rules } from 'shared/lib/rules';
import { modal } from './modal';
import { IProductFormFields } from '../types';
import { createProduct } from './createProduct';
import { productsModel } from 'entities/Products/model';

const $productForm = createForm<IProductFormFields>({
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    description: {
      init: '',
      rules: [],
    },
    price: {
      init: '',
      rules: [rules.required()],
    },
    image: {
      init: null,
    },
  },
  validateOn: ['change', 'submit'],
});

sample({
  clock: $productForm.formValidated,
  source: modal.$store,
  filter: (modalParams) => !modalParams?.product,
  fn: ({ categoryId }, fields) => {
    const formData = new FormData();
    formData.append('category_id', categoryId);
    formData.append('image', fields.image);
    formData.append('name', fields.name.trim());
    formData.append('description', fields.description.trim());
    formData.append('price', fields.price.trim());
    return formData;
  },
  target: createProduct.events.create,
});

sample({
  clock: $productForm.formValidated,
  source: modal.$store,
  filter: (modalParams) => Boolean(modalParams?.product),
  fn: ({ categoryId, product }, fields) => {
    const formData = new FormData();
    formData.append('category_id', categoryId);
    formData.append('image', fields.image);
    formData.append('name', fields.name.trim());
    formData.append('description', fields.description.trim());
    formData.append('price', fields.price.trim());
    return { productId: product.id, formData };
  },
  target: productsModel.editProduct.events.edit,
});

sample({
  clock: productsModel.editProduct.effects.editFx.doneData,
  target: modal.resetModal,
});

export const form = {
  stores: {
    $productForm,
  },
};
