import { createEvent, createStore, sample } from 'effector';
import { IService } from 'shared/types/api/services';
import { endPoints } from 'shared/config/endPoints';
import { redirect } from 'shared/lib/redirect';
import { SELECTED_SERVICE_ID_CACHE_KEY } from '../config';

const resetService = createEvent();
const setService = createEvent<IService>();
const updateService = createEvent<IService>();
const $service = createStore<IService>(
  JSON.parse(localStorage.getItem(SELECTED_SERVICE_ID_CACHE_KEY)) ?? null,
);

sample({
  clock: [setService, updateService],
  fn: (value) => {
    if (value) {
      // localStorage.setItem(
      //   SELECTED_SERVICE_ID_CACHE_KEY,
      //   JSON.stringify(value),
      // );

      return value;
    }

    // localStorage.removeItem(SELECTED_SERVICE_ID_CACHE_KEY);

    return null;
  },
  target: $service,
});

sample({
  clock: setService,
  filter: Boolean,
  fn: () => endPoints.DEFAULT_PAGE,
  target: redirect,
});

sample({
  clock: resetService,
  fn: () => null,
  target: setService,
});

export const selectedService = {
  stores: {
    $service,
  },
  events: {
    resetService,
    updateService,
    setService,
  },
};
