import { createEffect, createEvent, createStore, sample } from 'effector';
import { productsApi } from 'entities/Products/api';
import { servicesModel } from 'entities/Services/model';
import {
  IEditProductParams,
  TUpdateProductFx,
  TUpdateStatusProductFx,
} from '../types';
import { products } from './products';
import { TUpdateStatusProductParams } from 'shared/types/api/products';

const edit = createEvent<IEditProductParams>();
const editFx = createEffect<TUpdateProductFx>(productsApi.updateProduct);

const $isPending = editFx.pending;

sample({
  clock: edit,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, { formData, productId }) => ({
    shopId: service.id,
    params: formData,
    productId,
  }),
  target: editFx,
});

sample({
  clock: editFx.doneData,
  fn: (res) => res.data,
  target: products.events.updateProduct,
});

const updateStatus = createEvent<TUpdateStatusProductParams>();
const updateStatusFx = createEffect<TUpdateStatusProductFx>(
  productsApi.updateStatusProduct,
);

sample({
  clock: updateStatus,
  target: updateStatusFx,
});

sample({
  clock: [editFx.doneData, updateStatusFx.doneData],
  fn: (res) => res.data,
  target: products.events.updateProduct,
});

const $pendingIds = createStore<string[]>([]);

sample({
  clock: [edit, updateStatus],
  source: $pendingIds,
  fn: (pendings, { productId }) => [...pendings, productId],
  target: $pendingIds,
});

sample({
  clock: [editFx.done, updateStatusFx.done],
  source: $pendingIds,
  fn: (pendings, { params }) =>
    pendings.filter((productId) => productId !== params.productId),
  target: $pendingIds,
});

export const editProduct = {
  events: {
    edit,
    updateStatus,
  },
  effects: {
    editFx,
  },
  pendings: {
    $isPending,
    $pendingIds,
  },
};
