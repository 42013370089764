import React, { FC } from 'react';
import { unavailableStatsCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { Skeleton } from 'shared/ui/atoms/Skeleton';
import { sharedCss } from 'shared/css';
import { Svg } from 'shared/ui/atoms/Svg';

export const UnavailableStats: FC = () => {
  return (
    <div className={unavailableStatsCss.container}>
      <Text size="h2">Здесь будет ваша статистика</Text>
      <div className={unavailableStatsCss.stats}>
        <Skeleton className={unavailableStatsCss.titleSkeleton} />
        <Skeleton className={unavailableStatsCss.profitSkeleton} />
        <div className={sharedCss.rowBetween}>
          {Array.from({ length: 7 }).map((_, index) => (
            <div key={index} className={unavailableStatsCss.dayBox}>
              <Skeleton className={unavailableStatsCss.stat} />
              <Skeleton className={unavailableStatsCss.date} />
            </div>
          ))}
        </div>
        <div className={unavailableStatsCss.overlay}>
          <Svg idIcon="icGrowth" width={36} height={36} />
          <Text size="h3" className={unavailableStatsCss.overlayTitle}>
            Она появится после <br /> публикации витрины
          </Text>
        </div>
      </div>
      <Text size="h2">А ниже активные заказы</Text>
    </div>
  );
};
