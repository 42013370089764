import {
  CATEGORIES,
  CREATE_CATEGORY,
  CREATE_PRODUCT,
  DELETE_CATEGORY,
  DELETE_PRODUCT,
  GET_CSV_TEMPLATE,
  GET_PRODUCT,
  GET_PRODUCTS,
  UPDATE_CATEGORY,
  UPDATE_PRODUCT,
  UPDATE_STATUS_PRODUCT,
} from 'shared/api/endpoints';
import { API, FORM_DATA } from 'shared/api/executor';
import {
  TCreateCategory,
  TCreateProduct,
  TDeleteCategory,
  TDeleteProduct,
  TGetCategories,
  TGetProduct,
  TGetProducts,
  TUpdateCategory,
  TUpdateProduct,
  TUpdateStatusProduct,
} from '../types';

const getProducts: TGetProducts = (shopId) => API().get(GET_PRODUCTS(shopId));

const getProduct: TGetProduct = (params) =>
  API().get(GET_PRODUCT(params.shopId, params.productId));

const createProduct: TCreateProduct = ({ shopId, params }) =>
  API().post(CREATE_PRODUCT(shopId), params, {
    headers: { 'Content-Type': FORM_DATA },
  });

const updateProduct: TUpdateProduct = ({ shopId, productId, params }) =>
  API().post(UPDATE_PRODUCT(shopId, productId), params, {
    headers: { 'Content-Type': FORM_DATA },
  });

const deleteProduct: TDeleteProduct = (params) =>
  API().post(DELETE_PRODUCT(params.shopId, params.productId));

const updateStatusProduct: TUpdateStatusProduct = ({
  shopId,
  productId,
  params,
}) => API().post(UPDATE_STATUS_PRODUCT(shopId, productId), params);

// CATEGORIES
const getCategories: TGetCategories = (params) => API().get(CATEGORIES(params));

const createCategory: TCreateCategory = ({ shopId, params }) =>
  API().post(CREATE_CATEGORY(shopId), params);

const updateCategory: TUpdateCategory = ({ shopId, categoryId, params }) =>
  API().post(UPDATE_CATEGORY(shopId, categoryId), params);

const deleteCategory: TDeleteCategory = (params) =>
  API().post(DELETE_CATEGORY(params.shopId, params.categoryId));

const getCsvTemplate = () => API().get(GET_CSV_TEMPLATE);

export const productsApi = {
  getProducts,
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
  updateStatusProduct,
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  getCsvTemplate,
};
