import { css } from '@linaria/core';
import { theming } from '.';
import { setAlpha } from './lib/setAlpha';

export const globalClass = css`
  width: 100%;
  height: 100%;

  * {
    box-sizing: border-box;
  }
  ~ .ReactModalPortal {
    * {
      box-sizing: border-box;
    }
  }

  .ReactModal__Content {
    outline: none;
  }

  :global() {
    @font-face {
      font-family: 'Euclid';
      font-weight: 700;
      src: url('shared/assets/fonts/Euclid/Euclid-Circular-A-Bold.woff2')
        format('woff2');
    }

    @font-face {
      font-family: 'Euclid';
      font-weight: 600;
      src: url('shared/assets/fonts/Euclid/Euclid-Circular-A-SemiBold.woff2')
        format('woff2');
    }

    @font-face {
      font-family: 'Euclid';
      font-weight: 500;
      src: url('shared/assets/fonts/Euclid/Euclid-Circular-A-Medium.woff2')
        format('woff2');
    }

    @font-face {
      font-family: 'Euclid';
      font-weight: 400;
      src: url('shared/assets/fonts/Euclid/Euclid-Circular-A-Regular.woff2')
        format('woff2');
    }

    @font-face {
      font-family: 'Euclid';
      font-weight: 300;
      src: url('shared/assets/fonts/Euclid/Euclid-Circular-A-Light.woff2')
        format('woff2');
    }

    body {
      margin: 0;
      font-family: 'Euclid', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    html {
      scroll-behavior: smooth;
    }

    html,
    body {
      height: 100%;
      user-select: none;
    }

    body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    #root {
      min-width: 100vw;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }

    div {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      font-family: 'Euclid';
      margin: 0;
    }

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      -webkit-transition: 100ms;
      -o-transition: 100ms;
      transition: 100ms;
      padding: 0;
    }

    a {
      cursor: pointer;
      text-decoration: none;
    }

    input {
      outline: none;
      background-color: transparent;
      border: none;
    }

    .ReactModal__Body--open {
      overflow: hidden;
    }

    input,
    textarea,
    button,
    select,
    label,
    a {
      box-sizing: border-box;
      font-family: 'Euclid';
      -webkit-tap-highlight-color: transparent;
    }

    .contexify {
      padding: 0 !important;
      border-radius: 12px !important;

      ${theming((theme) => ({
        backgroundColor: theme.background.bg2,
        boxShadow: theme.commonShadow3,
      }))}
    }

    .contexify_itemContent {
      justify-content: space-between;
      padding: 12px 16px !important;
    }

    .contexify_item:not(.contexify_item--disabled):hover,
    .contexify_item:not(.contexify_item--disabled):focus {
      .contexify_itemContent {
        ${theming((theme) => ({
          backgroundColor: `${setAlpha(theme.background.bg1, 0.2)} !important`,
        }))}
      }
    }

    .contexify_separator {
      margin: 0 !important;
      ${theming((theme) => ({
        backgroundColor: theme.border.primary,
      }))}
    }

    .react-ios-time-picker-main {
      display: none;
    }

    .react-ios-time-picker {
      width: 100%;
      margin-bottom: 0 !important;
      border-radius: 32px 32px 0 0;
      overflow: hidden;

      ${theming((theme) => ({ boxShadow: theme.modalShadow }))}
    }

    .react-ios-time-picker-transition {
      animation: fade-in 150ms ease-out;
    }

    @keyframes fade-in {
      0% {
        transform: translateY(150px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .react-ios-time-picker-container {
      width: 100% !important;
      display: flex;
      justify-content: center;
      position: relative;
      overflow: hidden;
      padding: 20px 0;

      ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}
    }

    .react-ios-time-picker-hour {
      position: relative;
      width: 100% !important;
      overflow: hidden;
      z-index: 100;
      margin-right: 5px;
    }

    .react-ios-time-picker-minute {
      position: relative;
      width: 100% !important;
      overflow: hidden;
      z-index: 100;
      margin-left: 5px;
    }

    .react-ios-time-picker-hour-format {
      position: relative;
      width: 40px;
      overflow: hidden;
      z-index: 100;
    }

    .react-ios-time-picker-fast {
      transition: transform 700ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
    }

    .react-ios-time-picker-slow {
      transition: transform 600ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
    }

    .react-ios-time-picker-selected-overlay {
      position: absolute;
      border-radius: 6px;
      pointer-events: none;
      margin: 0 10px;
      left: 0;
      right: 0;
      z-index: 1;

      ${theming((theme) => ({
        backgroundColor: setAlpha(theme.colors.black, 0.03),
      }))}
    }

    .react-ios-time-picker-top-shadow {
      position: absolute;
      top: 0;
      width: 100%;
      background: #0009;
      background: linear-gradient(180deg, #0009 0%, #1c1c1c 100%);
    }

    .react-ios-time-picker-bottom-shadow {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #0009;
      background: linear-gradient(0deg, #0009 0%, hsla(0, 0%, 11%, 1) 100%);
    }

    .react-ios-time-picker-cell-hour {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: end;
      align-items: center;
      user-select: none;
      transition: all 100ms linear;
    }
    .react-ios-time-picker-cell-minute {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: start;
      align-items: center;
      user-select: none;
      transition: all 100ms linear;
    }
    .react-ios-time-picker-cell-hour-format {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: end;
      align-items: center;
      user-select: none;
      transition: all 100ms linear;
    }

    .react-ios-time-picker-cell-inner-hour {
      width: fit-content;
      height: 100%;
      transition: all 100ms linear;
      cursor: pointer;
      border-radius: 7px;
      line-height: 35px;
      text-align: center;
      display: flex;
      justify-content: end;
      align-items: center;
      font-size: 17px !important;
      padding: 0 10px;

      ${theming((theme) => ({ color: theme.text.secondary }))}
    }

    .react-ios-time-picker-cell-inner-hour-format {
      width: fit-content;
      height: 100%;
      transition: all 100ms linear;
      cursor: pointer;
      border-radius: 7px;
      line-height: 35px;
      text-align: center;
      display: flex;
      justify-content: end;
      align-items: center;
      font-size: 17px !important;
      padding: 0 10px;

      ${theming((theme) => ({ color: theme.text.secondary }))}
    }

    .react-ios-time-picker-cell-inner-minute {
      width: fit-content;
      height: 100%;
      transition: all 100ms linear;
      cursor: pointer;
      border-radius: 7px;
      line-height: 35px;
      text-align: center;
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: 17px !important;
      padding: 0 10px;

      ${theming((theme) => ({ color: theme.text.secondary }))}
    }

    .react-ios-time-picker-cell-inner-hour:hover,
    .react-ios-time-picker-cell-inner-minute:hover,
    .react-ios-time-picker-cell-inner-hour-format:hover {
      ${theming((theme) => ({
        color: theme.colors.white,
        backgroundColor: setAlpha(theme.colors.blue, 0.5),
      }))}
    }

    .react-ios-time-picker-cell-inner-selected {
      font-size: 22.5px !important;
      ${theming((theme) => ({ color: theme.text.primary }))}
    }

    .react-ios-time-picker-cell-inner-hour-format-selected {
      font-weight: 400;
      color: #f7f7f7;
    }

    .react-ios-time-picker-btn-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      z-index: 100;

      ${theming((theme) => ({
        backgroundColor: theme.background.bg2,
        borderBottomColor: theme.border.primary,
      }))}
    }

    .react-ios-time-picker-btn {
      padding: 10px 15px;
      font-size: 16px !important;
      transition: all 150ms linear;
      font-weight: 500;
      z-index: 1;

      ${theming((theme) => ({ color: theme.colors.blue }))}
    }

    .react-ios-time-picker-btn:hover {
      opacity: 0.6;
    }

    .react-ios-time-picker-btn-cancel {
      font-size: 12px;
      font-weight: 300;
    }

    .react-ios-time-picker-popup {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      z-index: 99998;
    }

    .react-ios-time-picker-popup-overlay {
      display: none;
    }

    .react-ios-time-picker-input {
      cursor: text;
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid #0005;
    }

    .react-ios-time-picker-colon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #f7f7f7;
      position: relative;
      z-index: 100;
      font-weight: 600;
    }

    .react-ios-time-picker-cell-inner-hidden {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .react-ios-time-picker-hour-format-transition {
      transition: transform 100ms ease-out;
    }
  }
`;

export const toastContainerClass = css`
  z-index: 10000 !important;
`;

export const toastClass = css`
  -webkit-backdrop-filter: blur(17.5px);
  backdrop-filter: blur(6px);
  padding: 0;

  ${theming((theme) => ({
    backgroundColor: `${setAlpha(theme.background.bg2, 0.7)}`,
    boxShadow: theme.commonShadow,
  }))}

  &.Toastify__toast {
    padding: 8px;
  }
`;
