import { captureException, withScope } from '@sentry/react';
import Axios, { AxiosError } from 'axios';
import { authModel } from 'entities/Auth/model';
import {
  LOCAL_STORAGE_CREDENTIALS,
  LOCAL_STORAGE_MERCHANT_INFO,
} from 'shared/config/constants';
import { IAuth } from 'shared/types/api/auth';

export const APPLICATION_JSON = 'application/json';
export const FORM_DATA = 'multipart/form-data';

export const API = (baseURL: string = process.env.REACT_APP_API_HOST) => {
  const credentials = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CREDENTIALS) ?? '{}',
  );
  const merchantInfo = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_MERCHANT_INFO),
  ) as IAuth;

  const instance = Axios.create({
    baseURL,
    timeout: 30000,
    headers: {
      Accept: APPLICATION_JSON,
      'Content-Type': APPLICATION_JSON,
      ...(merchantInfo && { Authorization: `Bearer ${merchantInfo?.token}` }),
      ...(credentials && {
        'Accept-Language': credentials?.lang ?? null,
        'Accept-Theme': credentials?.theme ?? null,
        'Accept-Style': credentials?.style ?? null,
        'Accept-AppVersion': credentials?.appVersion ?? null,
        'Accept-Platform': credentials?.platform ?? null,
      }),
    },
  });

  instance.interceptors.response.use(
    (response) => {
      console.log({
        method: response.config.url,
        data: response.data,
      });
      return response;
    },
    (error: AxiosError) => {
      console.error(error);
      withScope((scope) => {
        scope.setExtra('axiosRequest', {
          url: error.config.url,
          method: error.config.method,
          data: error.config.data,
          params: error.config.params,
        });

        // Optionally, add the response data (in case it's available)
        if (error.response) {
          scope.setExtra('axiosResponse', {
            status: error.response.status,
            data: error.response.data,
          });
        }

        captureException(error);
      });

      if (error.response.status === 401) {
        authModel.logout.events.logoutAccount();
      }

      return Promise.reject(error);
    },
  );

  return instance;
};
