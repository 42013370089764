import { createEvent, createStore, sample } from 'effector';

const resetElId = createEvent();

const scrollToElId = createEvent<string>();

const $elId = createStore<string>(null).reset(resetElId);

sample({
  clock: scrollToElId,
  target: $elId,
});

export const scrollToElIdModel = {
  stores: {
    $elId,
  },
  events: {
    scrollToElId,
    resetElId,
  },
};
