import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { ILocales } from 'shared/translations/config';
import { ru } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

export const useGetCookies = () => {
  useEffect(() => {
    const lang =
      (Cookies.get('click-language')?.toUpperCase() as ILocales) ?? null;
    const session = Cookies.get('click-web-session');
    const theme = Cookies.get('click-theme');
    const appVersion = Cookies.get('click-appversion');
    const platform = Cookies.get('click-platform');
    const style = Cookies.get('click-style');

    // localStorage.setItem(
    //   LOCAL_STORAGE_CREDENTIALS,
    //   JSON.stringify({ lang, session, theme, appVersion, platform, style }),
    // );

    setDefaultOptions({ locale: ru });

    // if (theme) {
    //   if (theme === 'dark') {
    //     themeModel.events.setTheme(EThemeTypes.light);
    //   }
    //   if (theme === 'light') {
    //     themeModel.events.setTheme(EThemeTypes.dark);
    //   }
    // }

    // if (lang) {
    //   const { i18n } = useTranslation();
    //   i18n.changeLanguage(lang);
    //   translationsModel.events.setLocale(lang);
    //   localStorage.setItem(LOCALE_LOCAL_STORAGE_KEY, lang);
    // }
  });
};
