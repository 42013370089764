import React, { FC } from 'react';
import { tabBarCss } from './style';
import { tabs } from './config';
import { Tab } from './ui/Tab';

export const TabBar: FC = () => {
  return (
    <div className={tabBarCss.container}>
      {tabs.map((tab) => (
        <Tab key={tab.endPoint} data={tab} />
      ))}
    </div>
  );
};
