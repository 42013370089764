import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import RU from './locales/ru.json';
import UZ from './locales/uz.json';
import EN from './locales/en.json';
import { ILocales, LOCALE_LOCAL_STORAGE_KEY } from './config';

i18next.use(initReactI18next).init({
  resources: {
    RU: {
      translation: RU,
    },
    UZ: {
      translation: UZ,
    },
    EN: {
      translation: EN,
    },
  },
  fallbackLng: localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY) ?? ILocales.ru,
  interpolation: {
    escapeValue: false,
  },
});

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type TLocaleKey = NestedKeyOf<typeof RU> | '';
