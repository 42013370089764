import React, { FC, Fragment, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { servicesModel } from 'entities/Services/model';
import { Outlet, useNavigate } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { TabBar } from 'widgets/TabBar';

export const AuthElement: FC = () => {
  const navigate = useNavigate();
  const [selectedService] = useUnit([
    servicesModel.selectedService.stores.$service,
  ]);

  useEffect(() => {
    if (!selectedService) {
      navigate(endPoints.MY_SHOPS, { replace: true });
    }
  }, [selectedService]);

  return (
    <Fragment>
      <Outlet />
      <TabBar />
    </Fragment>
  );
};
