export enum ESpriteName {
  default = 'default',
  toast = 'toast'
}

export interface ISvg {
  idIcon: string;
  width?: string | number;
  height?: string | number;
  spriteName?: keyof typeof ESpriteName;
  className?: string;
  id?: string;
  testId?: string;
  color?: 'default' | 'secondary' | 'disabled';
  onClick?: () => void;
}
