import React, { FC } from 'react';
import { clientInfoCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { IClientInfoProps } from './types';
import { Input } from 'shared/ui/atoms/Input';

export const ClientInfo: FC<IClientInfoProps> = ({ name = '', phone = '' }) => {
  return (
    <div className={clientInfoCss.container}>
      <Text size="h3">Информация о клиенте</Text>
      <div className={clientInfoCss.info}>
        <div>
          <Text color="secondary">Имя</Text>
          <Text>{name}</Text>
        </div>
        <div className={clientInfoCss.divider} />
        <div>
          <Text color="secondary">Контакт</Text>
          <Input
            isPattern
            isDefaultStyle={false}
            patternProps={{
              format: '+### (##) ### ## ##',
              mask: '_',
              allowEmptyFormatting: true,
            }}
            inputProps={{ value: phone, readOnly: true }}
          />
        </div>
      </div>
    </div>
  );
};
