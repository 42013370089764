import React, { FC } from 'react';
import { orderTagCss } from './style';
import { IOrderTagProps } from './types';
import { useTranslation } from 'react-i18next';

export const OrderTag: FC<IOrderTagProps> = ({
  status,
  title = '',
  variant = 'default',
}) => {
  const { t } = useTranslation();
  return (
    <span
      className={orderTagCss.container}
      data-status={status}
      data-variant={variant}>
      {status ? t(`orders.statuses.${status}`) : title}
    </span>
  );
};
