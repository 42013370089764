import { styled } from '@linaria/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { theming } from 'shared/theme';
import { css } from '@linaria/core';

export const PictureClassStyle = css`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
  span {
    width: 100%;
    height: 100%;
  }

  &[data-object-fit='cover'] {
    img {
      object-fit: cover;
    }
  }

  &[data-object-fit='contain'] {
    img {
      object-fit: contain;
    }
  }
`;

export const StyledLazyLoadImage = styled(LazyLoadImage)`
  width: auto;
  height: auto;
  border-radius: 0;
`;

export const StyledPlaceholder = styled.span`
  width: auto;
  height: auto;
  border-radius: 0;

  animation: rotate 1s infinite;

  @keyframes rotate {
    0% {
      ${theming((theme) => ({
        backgroundColor: theme.skeleton.color,
      }))}
    }
    50% {
      ${theming((theme) => ({
        backgroundColor: theme.skeleton.highlightColor,
      }))}
    }
    100% {
      ${theming((theme) => ({
        backgroundColor: theme.skeleton.color,
      }))}
    }
  }
`;
