import { modalFactory } from 'shared/lib/modalFactory';
import { TDateTimePickerModalParams } from '../types';

export const modal = modalFactory<TDateTimePickerModalParams>({
  isOpen: false,
  submitText: '',
  title: null,
  type: null,
  selectedDays: [],
  onSuccess: () => {},
});
