import { css } from '@linaria/core';
import { themingValue } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  padding: 76px 16px 80px 16px;
`;

const content = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const saveBtn = css`
  margin-top: 12px;
  min-height: 34px !important;
  width: auto !important;
`;

const boxAccentAnimation = css`
  animation: shadow 3s;
  animation-fill-mode: towards;
  animation-timing-function: ease-in-out;

  @keyframes shadow {
    50% {
      box-shadow: ${themingValue(theme => `0 0px 0px 12px ${setAlpha(theme.colors.blue, 0.4)}`)}
    }
  }
`;

export const shopCss = {
  container,
  content,
  saveBtn,
  boxAccentAnimation,
};
