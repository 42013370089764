import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  padding-top: 60px;
`;

const list = css`
  padding: 16px 16px 80px 16px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const listTitle = css`
  margin-bottom: 16px;
`;

const addNewCategory = css`
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}
`;

export const menuCss = {
  container,
  list,
  listTitle,
  addNewCategory,
};
