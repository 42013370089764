import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  border-radius: 16px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

const header = css``;

export const categoryCss = {
  container,
  header,
};
