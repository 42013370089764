import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  backdrop-filter: blur(5px);
  border: 1px solid;

  ${theming((theme) => ({
    backgroundColor: setAlpha(theme.background.bg1, 0.9),
    borderColor: theme.border.primary
  }))}

  border-bottom-color: transparent;
`;

export const tabBarCss = {
  container,
};
