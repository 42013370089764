import { css } from '@linaria/core';

const spinner = css`
  flex: 0;
  margin-top: 12px;
`;

export const authCss = {
  spinner,
};
