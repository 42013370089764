import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { shopModel } from 'pages/Shop/model';
import { CustomModal } from 'shared/ui/atoms/CustomModal';
import { Text } from 'shared/ui/atoms/Text';
import { telegramInstructionModalCss } from './style';
import { Button } from 'shared/ui/atoms/Button';
import { Svg } from 'shared/ui/atoms/Svg';
import CopyIcon from 'shared/assets/icons/Copy.svg?svgr';
import { showToast } from 'shared/lib/showToast';
import { EToastType } from 'shared/types/toast';

export const TelegramInstructionModal: FC = () => {
  const [isOpen] = useUnit([shopModel.telegramForm.tgModalFactory.$store]);

  const onClose = () => {
    shopModel.telegramForm.tgModalFactory.resetModal();
  };

  const onCopy = () => {
    navigator.clipboard.writeText('@notify_clickbot');
    showToast({
      className: telegramInstructionModalCss.toast,
      toastParams: {
        type: EToastType.Success,
        messageKey: "Скопировано в буфер"
      }
    })
  }

  return (
    <CustomModal
      className={telegramInstructionModalCss.modal}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <div className={telegramInstructionModalCss.header}>
        <Text size="h2">Инструкция</Text>
        <Button styleType="secondary" isRounded onClick={onClose}>
          <Svg idIcon="icCross" width={24} height={24} />
        </Button>
      </div>
      <div className={telegramInstructionModalCss.steps}>
        <div className={telegramInstructionModalCss.step}>
          <Text
            className={telegramInstructionModalCss.stepTitle}
            color="secondary">
            Шаг 1
          </Text>
          <Text>
            Создайте публичный канал в Telegram. <br />
            Получите <b>@channelusername</b>
          </Text>
        </div>
        <div className={telegramInstructionModalCss.step}>
          <Text
            className={telegramInstructionModalCss.stepTitle}
            color="secondary">
            Шаг 2
          </Text>
          <Text>
            Добавьте нашего бота{' '}
            <b className={telegramInstructionModalCss.channel} onClick={onCopy}>
              @notify_clickbot <CopyIcon width={16} height={16} />
            </b>{' '}
            в администраторы, с правами на отправку сообщений
          </Text>
        </div>
        <div className={telegramInstructionModalCss.step}>
          <Text
            className={telegramInstructionModalCss.stepTitle}
            color="secondary">
            Шаг 3
          </Text>
          <Text>Вставьте <b>@channelusername</b> канала в поле и сохраните</Text>
        </div>
      </div>
    </CustomModal>
  );
};
