import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  flex: 1;
`;

const editBtn = css`
  font-size: 14px;
  font-weight: 400;

  ${theming((theme) => ({ color: theme.text.accent }))}

  &:active {
    opacity: 0.7;
  }
`;

const staticValueBox = css`
  padding-top: 2px;
  padding-bottom: 8px;
  border-bottom: 1px solid;

  ${theming((theme) => ({ borderBottomColor: theme.border.primary }))}
`;

export const fieldCss = {
  container,
  editBtn,
  staticValueBox,
};
