import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import BigNumber from 'bignumber.js';
import 'shared/translations/i18n';
import 'react-spring-bottom-sheet/dist/style.css';
import 'react-circular-progressbar/dist/styles.css';
import * as Sentry from '@sentry/react';
import { Text } from 'shared/ui/atoms/Text';

BigNumber.config({
  EXPONENTIAL_AT: 1e9,
  FORMAT: {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: '',
  },
});

Sentry.init({
  dsn: 'https://5c418614f9d1b76d6075308b05627258@o4508244246134784.ingest.us.sentry.io/4508244255113216',
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ['localhost', process.env.REACT_APP_API_HOST],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary
    fallback={<Text size="h3">Temporary out of service</Text>}>
    <App />
  </Sentry.ErrorBoundary>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
