import React from 'react';
import { endPoints } from 'shared/config/endPoints';
import { Auth } from 'pages/Auth';
import { IRoute } from '../types';

export const loginRoutes: IRoute[] = [
  {
    path: endPoints.DEFAULT_PAGE,
    element: <Auth />,
  },
  {
    path: endPoints.LOGIN,
    element: <Auth />,
  },
];
