import React, { FC, Fragment, lazy, useEffect } from 'react';
import { PageContainer } from 'shared/ui/molecules/PageContainer';
import { homeCss } from './style';
import { Checklist } from './ui/Checklist';
import { Header } from 'widgets/Header';
import { homeModel } from './model';
import { useUnit } from 'effector-react';
import { servicesModel } from 'entities/Services/model';
import { LazyComponent } from 'shared/ui/molecules/LazyComponent';
import { UnavailableStats } from './ui/UnavailableStats';
import { Orders } from './ui/Orders';
import { AvailableStats } from './ui/AvailableStats';
import { statisticsModel } from 'entities/Statistics/model';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { Svg } from 'shared/ui/atoms/Svg';
import { NavLink } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';

const ShopPublishedModal = lazy(() =>
  import('entities/Services/ui/ShopPublishedModal').then(
    ({ ShopPublishedModal }) => ({
      default: ShopPublishedModal,
    }),
  ),
);

export const Home: FC = () => {
  const [checklist, service, isAllServicesPending, isStatsPending] = useUnit([
    homeModel.checklist.stores.$checklist,
    servicesModel.selectedService.stores.$service,
    servicesModel.services.pendings.isAllServicesPending,
    statisticsModel.stats.pendings.$isPending,
  ]);
  const filledCount = Object.values(checklist).filter(
    (isChecked) => isChecked,
  ).length;
  const allFilled = filledCount === 6;
  const isPending = isAllServicesPending || isStatsPending;

  useEffect(() => {
    homeModel.checklist.events.initChecklist();
  }, []);

  const onClose = () => {
    const el = document.createElement('a');
    el.href = 'https://business-test.click.uz';
    el.click();
    el.remove();
  };

  return (
    <PageContainer id="home" isScrollable className={homeCss.container}>
      <Header
        scrollEl="home"
        hasClose
        rightContent={
          <NavLink to={endPoints.MY_SHOPS}>
            <Svg idIcon="icShop" width={24} height={24} />
          </NavLink>
        }
        onClickClose={onClose}
      />
      {isPending ? (
        <SpinnerBlock size={40} />
      ) : (
        <div className={homeCss.content}>
          {(!allFilled || !service.status) && checklist ? (
            <Fragment>
              <Checklist
                data={{ checklist, filledCount, allFilled, totalCount: 6 }}
              />
              <UnavailableStats />
            </Fragment>
          ) : (
            <Fragment>
              <AvailableStats />
              <Orders />
            </Fragment>
          )}
        </div>
      )}
      <LazyComponent
        $store={servicesModel.shopPublishedModal.$store}
        withAnimation
        component={ShopPublishedModal}
      />
    </PageContainer>
  );
};
