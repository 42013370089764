import React, { FC, Fragment, Suspense } from 'react';
import { useUnit } from 'effector-react';
import { ILazyComponent } from './types';
import { useDelayedVisible } from 'shared/lib/useDelayedVisible';

export const LazyComponent: FC<ILazyComponent> = ({
  $store,
  component: Component,
  withAnimation,
}) => {
  const store = useUnit($store);

  const isOpen =
    store == null
      ? Boolean(store)
      : typeof store === 'object' && typeof store?.isOpen === 'boolean'
      ? Boolean(store?.isOpen)
      : Boolean(store);

  const { visible } = useDelayedVisible(isOpen, 500);

  if (withAnimation ? !visible : !isOpen) return null;

  return (
    <Suspense fallback={<Fragment />}>
      <Component />
    </Suspense>
  );
};
