import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const page = css`
  display: flex;
  flex-direction: column;
  height: 100%;

  &[data-is-scrollabe='true'] {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &[data-has-padding='true'] {
    padding: 16px;
  }
`;

const scrollable = css`
  overflow-y: auto;
  overflow-x: hidden;
`;

const pagePaddings = css`
  padding: 16px;
`;

const errorClass = css`
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  height: 20px;

  &[data-error-row-count='2'] {
    height: 40px;
  }

  ${theming((theme) => ({ color: theme.colors.red }))}
`;

const rowVerticalCenter = css`
  display: flex;
  align-items: center;
`;

const rowCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const rowBetween = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const fadeIn = css`
  animation: fadeIn 200ms ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

const colorRed = css`
  ${theming((theme) => ({ color: `${theme.colors.red} !important` }))}
`;

const colorBlue = css`
  ${theming((theme) => ({ color: `${theme.colors.blue} !important` }))}
`;

const tags = css`
  gap: 6px;
`;

export const sharedCss = {
  page,
  rowVerticalCenter,
  rowCenter,
  fadeIn,
  errorClass,
  scrollable,
  pagePaddings,
  rowBetween,
  colorRed,
  colorBlue,
  tags,
};
