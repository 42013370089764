import { endPoints } from 'shared/config/endPoints';

export const checklistConfig = [
  {
    elId: 'baseInfo',
    name: 'Заполните информацию о своей витрине',
    actionName: 'Заполнить',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'workingHours',
    name: 'Определите режим работы',
    actionName: 'Определить',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'backgroundImg',
    name: 'Загрузите титульное фото',
    actionName: 'Загрузить',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'location',
    name: 'Выберите локацию',
    actionName: 'Выбрать',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'telegramChannel',
    name: 'Создайте тг-канал для уведомлений',
    actionName: 'Создать',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'menu',
    name: 'Создайте меню',
    actionName: 'Заполнить',
    redirectTo: endPoints.MENU,
  },
];
