import React, { forwardRef } from 'react';
import { sharedCss } from 'shared/css';
import { IPageContainerProps } from './types';

export const PageContainer = forwardRef<HTMLDivElement, IPageContainerProps>(
  (
    {
      id = null,
      className = '',
      isScrollable = false,
      isAnimated = false,
      hasPadding = false,
      children,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        id={id}
        className={`${sharedCss.page} ${
          isAnimated && sharedCss.fadeIn
        } ${className}`}
        data-is-scrollabe={isScrollable}
        data-has-padding={hasPadding}>
        {children}
      </div>
    );
  },
);
