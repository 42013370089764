import { createEffect, createEvent, sample } from 'effector';
import { TUpdateServiceBackgroundFx } from '../types';
import { servicesApi } from '../api';
import { selectedService } from './selectedService';
import { services } from './services';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const updateBackground = createEvent<FormData>();
const updateBackgroundFx = createEffect<TUpdateServiceBackgroundFx>(
  servicesApi.updateServiceBackground,
);

const $isPending = updateBackgroundFx.pending;

sample({
  clock: updateBackground,
  source: selectedService.stores.$service,
  fn: (service, newAddress) => ({ shopId: service.id, params: newAddress }),
  target: updateBackgroundFx,
});

sample({
  clock: updateBackgroundFx.doneData,
  fn: (res) => res.data,
  target: [selectedService.events.updateService, services.events.updateService],
});

sample({
  clock: updateBackgroundFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'Титульное фото обновлено!',
    },
  }),
  target: showToastEvent,
});

export const background = {
  events: {
    updateBackground,
  },
  pendings: {
    $isPending,
  },
};
