import { createForm } from 'effector-forms';
import { IScheduleFormFields, TUpdateScheduleFx } from '../types';
import { createEffect, createEvent, sample } from 'effector';
import { servicesModel } from 'entities/Services/model';
import { defaultScheduleDays } from 'entities/Services/config';
import { servicesApi } from 'entities/Services/api';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const initForm = createEvent();
const resetForm = createEvent();

const $form = createForm<IScheduleFormFields>({
  fields: {
    days: {
      init: defaultScheduleDays,
    },
    from: {
      init: '',
    },
    to: {
      init: '',
    },
    full_time: {
      init: false,
    },
    cooking_time: {
      init: '',
    },
  },
  validateOn: ['change', 'submit'],
});

const updateScheduleFx = createEffect<TUpdateScheduleFx>(
  servicesApi.updateServiceSchedule,
);

const $isUpdateSchedulePending = updateScheduleFx.pending;

sample({
  clock: initForm,
  source: servicesModel.selectedService.stores.$service,
  filter: (service) => Boolean(service?.schedule_days),
  fn: (res) => ({
    days: res.schedule_days.days,
    from: res.schedule_days.time.from,
    to: res.schedule_days.time.to,
    full_time: res.schedule_days.time.full_time,
    cooking_time: res.schedule_days.cooking_time
  }),
  target: $form.setInitialForm,
});

sample({
  clock: initForm,
  source: servicesModel.selectedService.stores.$service,
  filter: (service) => !service?.schedule_days,
  fn: () => ({
    days: defaultScheduleDays,
    from: '',
    to: '',
    full_time: false,
    cooking_time: ''
  }),
  target: $form.setInitialForm,
});

sample({
  clock: $form.formValidated,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, fields) => ({
    shopId: service.id,
    params: {
      schedule_days: {
        days: fields.days,
        time: {
          from: fields.from,
          to: fields.to,
          full_time: fields.full_time,
        },
        cooking_time: fields.cooking_time
      },
    },
  }),
  target: updateScheduleFx,
});

sample({
  clock: updateScheduleFx.doneData,
  fn: (res) => res.data,
  target: [
    servicesModel.selectedService.events.updateService,
    servicesModel.services.events.updateService,
  ],
});

sample({
  clock: updateScheduleFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'График работы обновлен!',
    },
  }),
  target: showToastEvent,
});

export const scheduleForm = {
  stores: {
    $form,
  },
  events: {
    initForm,
  },
  pendings: {
    $isUpdateSchedulePending,
  },
};
