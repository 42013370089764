import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const button = css`
  border-radius: 12px;
  padding: 12px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}

  &:active {
    opacity: 0.7;
  }
`;

const header = css`
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 2px solid;

  ${theming((theme) => ({ borderBottomColor: theme.border.primary }))}

  svg {
    transform: rotate(180deg);
    margin-left: 16px;
  }
`;

const tags = css`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
`;

const productNames = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const date = css`
  margin-top: 6px;

  svg {
    margin-right: 6px;
  }
`;

export const orderCss = {
  button,
  header,
  tags,
  productNames,
  date,
};
