import { createEffect, createEvent, createStore, sample } from 'effector';
import { ordersApi } from 'entities/Orders/api';
import { servicesModel } from 'entities/Services/model';
import { IOrder } from 'shared/types/api/orders';
import { TGetOrdersFx } from '../types';

const reset = createEvent();
const $orders = createStore<IOrder[]>([]).reset(reset);

const getOrders = createEvent();
const getOrdersFx = createEffect<TGetOrdersFx>(ordersApi.getOrders);

const $isPending = getOrdersFx.pending;

sample({
  clock: getOrders,
  source: servicesModel.selectedService.stores.$service,
  fn: (service) => ({ shopId: service.id }),
  target: getOrdersFx,
});

sample({
  clock: getOrdersFx.doneData,
  fn: (res) => res.data,
  target: $orders,
});

const updateOrder = createEvent<IOrder>();

sample({
  clock: updateOrder,
  source: $orders,
  filter: (orders, newOrder) =>
    Boolean(newOrder && orders.some((order) => order.id === newOrder.id)),
  fn: (orders, newOrder) =>
    orders.map((order) => (order.id === newOrder.id ? newOrder : order)),
  target: $orders,
});

export const list = {
  stores: {
    $orders,
  },
  events: {
    getOrders,
    updateOrder,
    reset,
  },
  pendings: {
    $isPending,
  },
};
