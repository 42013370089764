import { IService } from 'shared/types/api/services';

export const getServiceChecklist = (service: IService) => ({
  baseInfo: Boolean(service?.support_phone),
  workingHours: Boolean(
    service?.schedule_days?.days?.length &&
      service?.schedule_days?.cooking_time &&
      service?.schedule_days?.time?.from &&
      service?.schedule_days?.time?.to,
  ),
  backgroundImg: Boolean(service?.background),
  location: Boolean(service?.address),
  telegramChannel: Boolean(service?.tg_channel_id),
  menu: Boolean(service?.has_menu),
});
