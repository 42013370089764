import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { accordionCss } from './style';
import { IAccordionProps } from './types';
import { TOnClick } from 'shared/types/eventHandlers';

export const Accordion: FC<IAccordionProps> = ({
  init = true,
  isDisabled = false,
  controllerElement,
  children,
  minHeight = 0,
  className,
}) => {
  const contentRef = useRef<HTMLDivElement>();
  const [isExpanded, setIsExpanded] = useState<boolean>(init);
  const [childrenHeight, setChildrenHeight] = useState<number>(0);

  useLayoutEffect(() => {
    new ResizeObserver((e) => {
      setChildrenHeight(e[0].target.clientHeight);
    }).observe(contentRef.current.children[0]);
  }, []);

  useEffect(() => {
    if (childrenHeight) {
      if (init) {
        contentRef.current.style.height = `${childrenHeight}px`;
        contentRef.current.style.opacity = `1`;
      } else {
        contentRef.current.style.height = `${minHeight}px`;
        contentRef.current.style.opacity = minHeight ? '1' : `0`;
      }
    }
  }, [childrenHeight]);

  const onToggle: TOnClick<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (isDisabled) return;

    if (isExpanded) {
      contentRef.current.style.height = `${minHeight}px`;
      contentRef.current.style.opacity = minHeight ? '1' : '0';
      setIsExpanded(false);
      return;
    }

    setIsExpanded(true);
    contentRef.current.style.height = `${childrenHeight}px`;
    contentRef.current.style.opacity = `1`;
  };

  return (
    <div className={`${accordionCss.container} ${className}`}>
      <div className={accordionCss.controller} onClick={onToggle}>
        {controllerElement({ isExpanded, isDisabled })}
      </div>
      <div ref={contentRef} className={accordionCss.content}>
        {children}
      </div>
    </div>
  );
};
