import React, { FC } from 'react';
import { Svg } from 'shared/ui/atoms/Svg';
import { closeAppButtonCss } from './style';
import { ICloseAppButtonProps } from './types';
import { useNavigate } from 'react-router-dom';

export const CloseAppButton: FC<ICloseAppButtonProps> = ({
  className = '',
  onClick,
}) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    onClick ? onClick() : navigate?.(-1);
  };

  return (
    <button
      type="button"
      className={`${closeAppButtonCss.button} ${className}`}
      onClick={onClickHandler}>
      <Svg idIcon="icCross" width={24} height={24} />
    </button>
  );
};
