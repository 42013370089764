import React, { FC, useCallback } from 'react';
import { Menu, useContextMenu } from 'react-contexify';
import { IContextMenuProps } from './types';
import 'react-contexify/ReactContexify.css';

export const ContextMenu: FC<IContextMenuProps> = ({
  children,
  id,
  className,
  onShown,
  onHidden,
}) => {
  const { hideAll } = useContextMenu({ id });

  const handleTouch = useCallback((event: TouchEvent) => {
    const rect = document
      .querySelector(`.context-menu-touch-${id}`)
      ?.getBoundingClientRect();
    const clientX = event.touches.item(0).clientX;
    const clientY = event.touches.item(0).clientY;

    if (!rect) return;
    if (
      clientX < rect.left - 50 ||
      clientX > rect.right + 50 ||
      clientY < rect.top - 50 ||
      clientY > rect.bottom + 50
    )
      hideAll();
  }, []);

  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      window.addEventListener('touchstart', handleTouch);
      onShown?.();
    } else {
      window.removeEventListener('touchstart', handleTouch);
      onHidden?.();
    }
  };

  return (
    <Menu
      className={`${className} context-menu-touch-${id}`}
      id={id}
      onVisibilityChange={onVisibilityChange}>
      {children}
    </Menu>
  );
};
