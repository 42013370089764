import { IProductListByCategory } from 'entities/Products/types';
import { ICategory, IProduct } from 'shared/types/api/products';

export const getProductListByCategory = ({
  products,
  categories,
}: {
  products: IProduct[];
  categories: ICategory[];
}): IProductListByCategory[] => {
  return categories.map<IProductListByCategory>((category) => ({
    category_id: category.id,
    category_name: category.name,
    isNew: Boolean(category?.isNew),
    products: products.filter((product) => product.category_id === category.id),
  }));
};
