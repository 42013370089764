import { createEvent, createStore, sample } from 'effector';
import { productsModel } from 'entities/Products/model';
import { servicesModel } from 'entities/Services/model';
import { IChecklist } from 'shared/types/api/common';

const resetChecklist = createEvent();

const initChecklist = createEvent();

const $checklist = createStore<IChecklist>({
  baseInfo: false,
  backgroundImg: false,
  workingHours: false,
  location: false,
  telegramChannel: false,
  menu: false,
}).reset(resetChecklist);

sample({
  clock: [
    initChecklist,
    servicesModel.selectedService.stores.$service,
    productsModel.products.stores.$list,
  ],
  source: {
    products: productsModel.products.stores.$list,
    service: servicesModel.selectedService.stores.$service,
  },
  filter: ({ service }) => Boolean(service),
  fn: ({ products, service }) => ({
    baseInfo: Boolean(service?.support_phone),
    workingHours: Boolean(
      service?.schedule_days?.days?.length &&
        service?.schedule_days?.cooking_time &&
        service?.schedule_days?.time?.from &&
        service?.schedule_days?.time?.to,
    ),
    backgroundImg: Boolean(service?.background),
    location: Boolean(service?.address),
    telegramChannel: Boolean(service?.tg_channel_id),
    menu: Boolean(products?.length),
  }),
  target: $checklist,
});

sample({
  clock: servicesModel.selectedService.stores.$service,
  filter: (service) => !service,
  target: resetChecklist,
});

export const checklist = {
  stores: {
    $checklist,
  },
  events: {
    initChecklist,
  },
};
