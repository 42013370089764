export const getWorkingHoursModalTitle = (type) => {
  switch (type) {
    case 'days':
      return 'Выберите дни работы';
    case 'from':
      return 'Выберите время открытия';
    case 'to':
      return 'Выберите время закрытия';
    case 'preparingTime':
      return 'Выберите время готовки';
    default:
      return null;
  }
};
