import React, { FC, Fragment } from 'react';
import { productsCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { IProductsProps } from './types';
import { sharedCss } from 'shared/css';
import BigNumber from 'bignumber.js';

export const Products: FC<IProductsProps> = ({ products = [] }) => {
  const totalAmount = products.reduce((prev, acc) => prev + acc.price, 0);
  return (
    <div className={productsCss.container}>
      <Text size="h3">Состав заказа</Text>
      <div className={productsCss.info}>
        {products.map((product, index) => (
          <Fragment key={product._id}>
            <div>
              <div className={sharedCss.rowBetween}>
                <Text color="secondary">{`Позиция № ${index + 1}`}</Text>
                <Text color="secondary">{`${BigNumber(
                  product.price,
                ).toFormat(2)} сум`}</Text>
              </div>
              <Text>{product.name}</Text>
            </div>
            <div className={productsCss.divider} />
          </Fragment>
        ))}
        <div>
          <Text color="secondary">Итого</Text>
          <Text>{`${BigNumber(totalAmount).toFormat(2)} сум`}</Text>
        </div>
      </div>
    </div>
  );
};
