import { createEffect, createEvent, createStore, sample } from 'effector';
import { ICategory } from 'shared/types/api/products';
import { productsApi } from '../api';
import { servicesModel } from 'entities/Services/model';
import {
  TCreateCategoryFx,
  TDeleteCategoryFx,
  TGetCategoriesFx,
  TUpdateCategoryFx,
} from '../types';
import { pending } from 'patronum';
import { products } from './products';

const $categories = createStore<ICategory[]>([]);

const getCategories = createEvent();
const getCategoriesFx = createEffect<TGetCategoriesFx>(
  productsApi.getCategories,
);
const $isCategoriesPending = getCategoriesFx.pending;

sample({
  clock: getCategories,
  source: servicesModel.selectedService.stores.$service,
  fn: (service) => service.id,
  target: getCategoriesFx,
});

sample({
  clock: getCategoriesFx.doneData,
  fn: (res) => res.data,
  target: $categories,
});

const pushCategory = createEvent<ICategory>();

sample({
  clock: pushCategory,
  source: $categories,
  filter: (_, newCategory) => Boolean(newCategory),
  fn: (categories, newCategory) => [...categories, newCategory],
  target: $categories,
});

const deleteCategory = createEvent<string>();
const deleteNewCategory = createEvent<string>();
const deleteCategoryFx = createEffect<TDeleteCategoryFx>(
  productsApi.deleteCategory,
);

const $deletePendings = createStore<string[]>([]);

sample({
  clock: deleteCategory,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, categoryId) => ({ shopId: service.id, categoryId }),
  target: deleteCategoryFx,
});

sample({
  clock: deleteNewCategory,
  source: $categories,
  fn: (categories, categoryId) =>
    categories.filter((category) => category.id !== categoryId),
  target: $categories,
});

sample({
  clock: deleteCategory,
  source: $deletePendings,
  fn: (pendings, categoryId) => [...pendings, categoryId],
  target: $deletePendings,
});

sample({
  clock: deleteCategoryFx.done,
  source: $deletePendings,
  fn: (pendings, { params }) =>
    pendings.filter((categoryId) => categoryId !== params.categoryId),
  target: $deletePendings,
});

sample({
  clock: deleteCategoryFx.done,
  source: $categories,
  filter: (_, newCategory) => Boolean(newCategory),
  fn: (categories, { params }) =>
    categories.filter((category) => category.id !== params.categoryId),
  target: $categories,
});

sample({
  clock: deleteCategoryFx.done,
  fn: ({ params }) => params.categoryId,
  target: products.events.removeProductsByCategoryId,
});

const createCategory = createEvent<ICategory>();
const createCategoryFx = createEffect<TCreateCategoryFx>(
  productsApi.createCategory,
);

const $createPendings = createStore<string[]>([]);

sample({
  clock: createCategory,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, category) => ({
    shopId: service.id,
    categoryId: category.id,
    params: {
      name: category.name.trim(),
      status: true,
    },
  }),
  target: createCategoryFx,
});

sample({
  clock: createCategory,
  source: $createPendings,
  fn: (pendings, category) => [...pendings, category.id],
  target: $createPendings,
});

sample({
  clock: createCategoryFx.done,
  source: $createPendings,
  fn: (pendings, { params }) =>
    pendings.filter((categoryId) => categoryId !== params.categoryId),
  target: $createPendings,
});

sample({
  clock: createCategoryFx.done,
  source: $categories,
  filter: (_, newCategory) => Boolean(newCategory),
  fn: (categories, { params, result }) =>
    categories.map((category) =>
      category.id === params.categoryId ? result.data : category,
    ),
  target: $categories,
});

const updateCategory = createEvent<ICategory>();
const updateCategoryFx = createEffect<TUpdateCategoryFx>(
  productsApi.updateCategory,
);

const $updatePendings = createStore<string[]>([]);

sample({
  clock: updateCategory,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, category) => ({
    shopId: service.id,
    categoryId: category.id,
    params: {
      name: category.name.trim(),
      status: true,
    },
  }),
  target: updateCategoryFx,
});

sample({
  clock: updateCategory,
  source: $updatePendings,
  fn: (pendings, category) => [...pendings, category.id],
  target: $updatePendings,
});

sample({
  clock: updateCategoryFx.done,
  source: $updatePendings,
  fn: (pendings, { params }) =>
    pendings.filter((categoryId) => categoryId !== params.categoryId),
  target: $updatePendings,
});

sample({
  clock: updateCategoryFx.done,
  source: $categories,
  filter: (_, newCategory) => Boolean(newCategory),
  fn: (categories, { params, result }) =>
    categories.map((category) =>
      category.id === params.categoryId ? result.data : category,
    ),
  target: $categories,
});

const $isCategoryPending = pending({
  effects: [updateCategoryFx, deleteCategoryFx, createCategoryFx],
});

export const categories = {
  stores: {
    $categories,
  },
  events: {
    getCategories,
    pushCategory,
    createCategory,
    updateCategory,
    deleteCategory,
    deleteNewCategory,
  },
  pendings: {
    $isCategoriesPending,
    $deletePendings,
    $createPendings,
    $updatePendings,
    $isCategoryPending,
  },
};
