import React, { FC, useEffect } from 'react';
import { Box } from '../Box';
import { Field } from '../Field';
import { TOnChange, TOnSubmit } from 'shared/types/eventHandlers';
import { baseInfoCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { sharedCss } from 'shared/css';
import { useForm } from 'effector-forms';
import { shopModel } from 'pages/Shop/model';
import { useUnit } from 'effector-react';
import { servicesModel } from 'entities/Services/model';
import { Button } from 'shared/ui/atoms/Button';
import { shopCss } from 'pages/Shop/style';
import { NumberFormatValues } from 'react-number-format';

export const BaseInfo: FC = () => {
  const [selectedService, isPending, businessServices, isAllServicesPending] =
    useUnit([
      servicesModel.selectedService.stores.$service,
      servicesModel.update.pendings.$isPending,
      servicesModel.services.stores.$businessServices,
      servicesModel.services.pendings.isAllServicesPending,
    ]);
  const bussinesService = businessServices?.find(
    (service) =>
      service.clickServiceId.toString() === selectedService?.service_id,
  );

  useEffect(() => {
    selectedService && shopModel.baseInfoForm.events.initForm();
  }, [selectedService]);

  const { fields, errorText, submit } = useForm(
    shopModel.baseInfoForm.stores.$form,
  );

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onValueChange =
    (name: string) =>
    ({ value }: NumberFormatValues) => {
      fields[name].onChange(value);
    };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Box id='baseInfo' title="Информация о витрине" contentClassName={baseInfoCss.container}>
      <form onSubmit={onSubmit}>
        <Field
          label="Название"
          value={fields.name.value}
          errorKey={errorText('name')}
          inputProps={{
            name: 'name',
            value: fields.name.value,
            onChange,
          }}
        />
        <Field
          label="Контакт"
          isEditable={false}
          value={selectedService?.admin_phone}
          inputProps={{
            readOnly: true,
          }}
          patternProps={{
            format: '+### (##) ### ## ##',
            mask: '_',
            allowEmptyFormatting: true,
          }}
        />
        <Field
          label="Контакт для клиентов"
          value={fields.support_phone.value}
          errorKey={errorText('support_phone')}
          inputProps={{
            name: 'support_phone',
            placeholder: 'Введите номер телефона',
            value: fields.support_phone.value,
          }}
          patternProps={{
            format: '+### (##) ### ## ##',
            mask: '_',
            allowEmptyFormatting: true,
            onValueChange: onValueChange('support_phone'),
          }}
        />
        <div className={baseInfoCss.boxOffice}>
          <div className={baseInfoCss.boxOfficeLeftContent}>
            <Text color="secondary">Касса</Text>
            <Text
              className={baseInfoCss.selectedBoxOffice}
              isEmpty={isAllServicesPending}>
              {bussinesService?.name ?? 'Неизвестно'}
            </Text>
          </div>
          <div
            className={`${sharedCss.rowCenter} ${baseInfoCss.boxOfficeStatus}`}
            data-is-active={true}>
            <div className={`${baseInfoCss.boxOfficeStatusDot}`} />
            <span>{'подключена'}</span>
          </div>
        </div>
        <Button
          styleType="accent"
          type="submit"
          className={shopCss.saveBtn}
          isLoading={isPending}>
          Сохранить
        </Button>
      </form>
    </Box>
  );
};
