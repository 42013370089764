import { IScheduleDay } from 'shared/types/api/services';

export const SELECTED_SERVICE_ID_CACHE_KEY = '@selectedServiceId';

export const defaultScheduleDays: IScheduleDay[] = [
  {
    name: 'Monday',
    is_available: false,
  },
  {
    name: 'Tuesday',
    is_available: false,
  },
  {
    name: 'Wednesday',
    is_available: false,
  },
  {
    name: 'Thursday',
    is_available: false,
  },
  {
    name: 'Friday',
    is_available: false,
  },
  {
    name: 'Saturday',
    is_available: false,
  },
  {
    name: 'Sunday',
    is_available: false,
  },
];
